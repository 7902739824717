import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import Surveys from "../presentation/Surveys";
import SurveyEditor from "../presentation/view/SurveyEditor";
import AddSurveyForm from "../presentation/view/AddSurveyForm";
import DisplayForm from "../presentation/view/DisplayForm";
import QuetionReport from "../presentation/view/QuetionReport";
import AnswerReport from "../presentation/view/AnswerReport";
import MyTasks from "../presentation/MyTasks";
import Tasks from "../presentation/Tasks";
import AddTaskForm from "../presentation/view/AddTaskForm";
import DetailTaskForm from "../presentation/view/DetailTaskForm";
import DetailMySurveys from "../presentation/view/DetailMySurveys";
import AssignSurvey from "../presentation/AssignSurvey";
import DetailMyTaskForm from "../presentation/view/DetailMyTaskForm";

export default function ModuleRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/survey/list" element={<Surveys />} />
        <Route path="/survey/add" element={<AddSurveyForm />} />
        <Route path="/survey/update/:id" element={<AddSurveyForm />} />
        <Route path="/survey/editor/:id" element={<SurveyEditor />} />
        <Route path="/survey/display/:type/:id" element={<DisplayForm />} />
        <Route path="/survey/question/report" element={<QuetionReport />} />
        <Route path="/survey/answer/report" element={<AnswerReport />} />
        <Route path="/mytask/list" element={<MyTasks />} />
        <Route path="/mytask/detail/list/:id" element={<DetailMySurveys />} />
        <Route path="/mytask/detail/:id" element={<DetailMyTaskForm />} />
        <Route path="/task/list" element={<Tasks />} />
        <Route path="/task/add" element={<AddTaskForm />} />
        <Route path="/task/update/:id" element={<AddTaskForm />} />
        <Route path="/task/detail/:id" element={<DetailTaskForm />} />
        <Route path="/task/assign/:id" element={<AssignSurvey />} />
      </Routes>
    </Provider>
  );
}
