import React from "react";
import { Modal, Button, Text, Stack } from "@mantine/core";
import { CheckCircle, Warning } from "@phosphor-icons/react";

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  onClose,
  message = "Hata!",
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      withCloseButton
      size={350}
      styles={{
        content: {
          borderRadius: "2rem",
          padding: ".8rem",
        },
      }}
    >
      <Stack align="center" spacing="xs">
        <Warning size={60} color="#FF0000" />
        <Text
          style={{ marginTop: 10, marginBottom: 20 }}
          weight={300}
          align={"center"}
        >
          {message}
        </Text>
        <Button
          className="state-modal-button success-modal-button"
          onClick={onClose}
          fullWidth
        >
          Tamam
        </Button>
      </Stack>
    </Modal>
  );
};

export default ErrorModal;
