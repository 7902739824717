// eslint-disable
import {
  Box,
  Button,
  Checkbox,
  Col,
  Grid,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";
import ReactQuill from "react-quill";
import { useForm } from "@mantine/form";
import "dayjs/locale/tr";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../connector/ModuleHook";
import moment from "moment";
import {
  CreateFormTaskService,
  DetailFormTaskService,
  FormListService,
  UpdateFormTaskService,
} from "../../interractor/SurveyInterractor";
import { FormAddResponse } from "../../data/response/FormAddResponse";
import { RootState } from "../../connector/ModuleStore";
import Swal from "sweetalert2";
import { DateInput, DatesProvider } from "@mantine/dates";
import dayjs from "dayjs";
import { CalendarBlank } from "phosphor-react";
import { CreateFormTaskRequest } from "../../data/request/CreateFormTaskRequest";
import { FormTaskDto } from "../../data/dto/FormTaskDto";
import { UpdateFormTaskRequest } from "../../data/request/UpdateFormTaskRequest";
import "react-quill/dist/quill.snow.css";

const AddTaskCard: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [survey, setSurvey] = useState<string>("");
  const [additionalPhoto, setAdditionalPhoto] = useState<boolean>(false);
  const [locationChecking, setLocationChecking] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    setFetching(true);
    if (id) {
      setShouldUpdate(true);
      dispatch(DetailFormTaskService({ formTaskId: id }));
    }
    dispatch(FormListService());
    ClearForm();
  }, [id]);

  const detailFormTaskService = useSelector(
    (state: RootState) => state.DetailFormTaskState.value
  );

  const detailFormTaskData = detailFormTaskService?.data?.formTaskDto;

  const formListService = useSelector(
    (state: RootState) => state.FormListState.value
  );

  const formListData = formListService?.data?.forms;

  useEffect(() => {
    setFetching(false);
  }, [formListData]);

  useEffect(() => {
    if (detailFormTaskData) {
      setName(detailFormTaskData.name);
      setDescription(detailFormTaskData.description);
      setStartDate(moment(detailFormTaskData.startDate).format("DD.MM.YYYY"));
      setEndDate(moment(detailFormTaskData.endDate).format("DD.MM.YYYY"));
      setSurvey(detailFormTaskData.formId);
      setAdditionalPhoto(detailFormTaskData.additionalPhoto);
      setLocationChecking(detailFormTaskData.locationChecking);
    }
  }, [detailFormTaskData]);

  const [submitted, setSubmitted] = useState(false);

  const [fetching, setFetching] = useState(false);

  const handleSave = () => {
    setSubmitted(true);
    if (
      name.trim() == "" ||
      description.trim() == "" ||
      survey === "" ||
      !startDate ||
      !endDate
    ) {
      setFetching(false);
      Swal.fire({
        title: "Lütfen Gerekli Alanları Doldurunuz",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#0097c4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tamam",
      });
      return;
    }
    if (!shouldUpdate) {
      const createFormTaskRequest: CreateFormTaskRequest = {
        formTaskDto: {
          name: name,
          description: description,
          startDate: moment(startDate, "DD.MM.YYYY").format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          endDate: moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss"),
          formId: survey,
          additionalPhoto: additionalPhoto,
          locationChecking: locationChecking,
          corporateId: "7ee48b54-972e-4790-9ef6-a705cd7e1a47",
        } as FormTaskDto,
      };
      dispatch(CreateFormTaskService(createFormTaskRequest)).then(
        (response) => {
          if (response !== undefined) {
            let responsePayload = response.payload as
              | FormAddResponse
              | undefined;
            if (responsePayload?.success) {
              form.reset();
              Swal.fire({
                title: "Görev başarı ile eklendi",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#0097c4",
                cancelButtonColor: "#d33",
                confirmButtonText: "Tamam",
              }).then((result) => {
                if (result.isConfirmed) {
                  ClearForm();
                  navigate("/task/list");
                }
              });
              setFetching(false);
            } else {
              Swal.fire({
                title: responsePayload?.userMessage,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#0097c4",
                cancelButtonColor: "#d33",
                confirmButtonText: "Tamam",
              });
              setFetching(false);
            }
          }
        }
      );
    } else {
      const updateFormTaskRequest: UpdateFormTaskRequest = {
        formTaskDto: {
          id: id,
          name: name,
          description: description,
          startDate: moment(startDate, "DD.MM.YYYY").format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          endDate: moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss"),
          formId: survey,
          additionalPhoto: additionalPhoto,
          locationChecking: locationChecking,
          corporateId: "7ee48b54-972e-4790-9ef6-a705cd7e1a47",
        } as FormTaskDto,
      };
      dispatch(UpdateFormTaskService(updateFormTaskRequest)).then(
        (response) => {
          if (response !== undefined) {
            let responsePayload = response.payload as
              | FormAddResponse
              | undefined;
            if (responsePayload?.success) {
              form.reset();
              Swal.fire({
                title: "Görev başarı ile düzenlendi",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#0097c4",
                cancelButtonColor: "#d33",
                confirmButtonText: "Tamam",
              }).then((result) => {
                if (result.isConfirmed) {
                  ClearForm();
                  navigate("/task/list");
                }
              });
              setFetching(false);
            } else {
              Swal.fire({
                title: responsePayload?.userMessage,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#0097c4",
                cancelButtonColor: "#d33",
                confirmButtonText: "Tamam",
              });
              setFetching(false);
            }
          }
        }
      );
    }
  };

  const ClearForm = () => {
    setName("");
    setDescription("");
    setSurvey("");
    setLocationChecking(false);
    setAdditionalPhoto(false);
  };

  const form = useForm({
    validate: {
      name: (value: any) => {
        if (name.trim() == "") return "Form adı boş olamaz";
        return null;
      },
      description: (value) =>
        description.trim() == "" ? "Açıklama gririlmelidir" : null,
      survey: (value) => (survey.trim() == "" ? "Form seçilmelidir" : null),
      startDate: (value) =>
        !startDate ? "Başlangıç zamanı gririlmelidir" : null,
      endDate: (value) => (!endDate ? "Bitiş zamanı gririlmelidir" : null),
    },
  });

  return (
    <Paper p={20} className="widgets" radius={rem(12)} bg="white">
      <Space h={"30px"} />

      <Stack
        dir="column"
        p={20}
        justify={"center"}
        align={"center"}
        pos={"relative"}
      >
        <LoadingOverlay
          visible={fetching}
          overlayBlur={2}
          loaderProps={{ size: "md", color: "#0097c4", variant: "oval" }}
          pos={"absolute"}
        />

        <form
          onSubmit={form.onSubmit((values) => {})}
          style={{ width: "100%" }}
        >
          <Grid>
            <Grid.Col sm={12} md={6}>
              <Col span={12}>
                <Text
                  style={{
                    fontStyle: "bold",
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Görev Adı
                </Text>
                <TextInput
                  tabIndex={1}
                  placeholder="Görev adı giriniz"
                  {...form.getInputProps("name")}
                  onChange={(event) => (
                    form.getInputProps("name").onChange(event.target.value),
                    setName(event.target.value)
                  )}
                  value={name}
                  sx={{
                    Input: {
                      height: "48px",
                      borderRadius: "18px",
                    },
                  }}
                />
              </Col>
              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Görev Başlangıç Tarihi
                </Text>
                <DatesProvider
                  settings={{
                    locale: dayjs.locale("tr"),
                    firstDayOfWeek: 1,
                    weekendDays: [0, 6],
                  }}
                >
                  <DateInput
                    tabIndex={9}
                    valueFormat="DD.MM.YYYY"
                    rightSection={<CalendarBlank size={16} color="#6c757d" />}
                    clearable
                    allowDeselect
                    hideOutsideDates
                    placeholder="Görev başlangıç tarihi seçiniz"
                    {...form.getInputProps("startDate")}
                    minDate={moment().toDate()}
                    maxDate={
                      endDate
                        ? moment(endDate, "DD.MM.YYYY").toDate()
                        : moment().add(1, "year").toDate()
                    }
                    onChange={(value) => {
                      const dateValue = value ? new Date(value) : new Date();
                      if (dateValue) {
                        setStartDate(moment(value).format("DD.MM.YYYY"));
                        form.getInputProps("startDate").onChange(value);
                      }
                    }}
                    value={
                      startDate
                        ? moment(startDate, "DD.MM.YYYY").toDate()
                        : null
                    }
                    sx={{
                      Input: {
                        height: "48px",
                        borderRadius: "18px",
                      },
                    }}
                    disabled={
                      (shouldUpdate &&
                        startDate &&
                        moment(startDate, "DD.MM.YYYY").isSameOrBefore(
                          moment(),
                          "day"
                        )) ||
                      false
                    }
                  />
                </DatesProvider>
              </Col>

              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Form
                </Text>
                <Select
                  searchable
                  placeholder="Form seçin"
                  data={
                    formListData?.map((form) => ({
                      value: form.id!.toString(),
                      label: form.name,
                    })) || []
                  }
                  {...form.getInputProps("survey")}
                  onChange={(value) => {
                    if (value) {
                      form.getInputProps("survey").onChange(value);
                      setSurvey(value);
                    }
                  }}
                  value={survey}
                  sx={{
                    Input: {
                      height: "48px",
                      borderRadius: "18px",
                    },
                  }}
                />
              </Col>

              <Col span={12} pt={"20px"}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                ></Text>
                <Checkbox
                  checked={locationChecking}
                  label="Konum kontrolü yapılacak mı?"
                  onChange={(event) =>
                    setLocationChecking(event.currentTarget.checked)
                  }
                />
              </Col>
            </Grid.Col>

            <Grid.Col sm={12} md={6}>
              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Açıklama
                </Text>

                <ReactQuill
                  theme="snow"
                  value={description}
                  placeholder="Açıklama giriniz"
                  onChange={(value) => (
                    form.getInputProps("description").onChange(value),
                    setDescription(value)
                  )}
                />
              </Col>
              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Görev Bitiş Tarihi
                </Text>
                <DatesProvider
                  settings={{
                    locale: dayjs.locale("tr"),
                    firstDayOfWeek: 1,
                    weekendDays: [0, 6],
                  }}
                >
                  <DateInput
                    tabIndex={9}
                    valueFormat="DD.MM.YYYY"
                    rightSection={<CalendarBlank size={16} color="#6c757d" />}
                    clearable
                    allowDeselect
                    hideOutsideDates
                    placeholder=" Görev bitiş tarihi seçiniz"
                    {...form.getInputProps("endDate")}
                    minDate={
                      startDate
                        ? moment(startDate, "DD.MM.YYYY").toDate()
                        : moment().subtract(100, "year").toDate()
                    }
                    maxDate={moment().add(1, "year").toDate()}
                    onChange={(value) => {
                      const dateValue = value ? new Date(value) : new Date();
                      if (dateValue) {
                        setEndDate(moment(value).format("DD.MM.YYYY"));
                        form.getInputProps("endDate").onChange(value);
                      }
                    }}
                    value={
                      endDate ? moment(endDate, "DD.MM.YYYY").toDate() : null
                    }
                    sx={{
                      Input: {
                        height: "48px",
                        borderRadius: "18px",
                      },
                    }}
                  />
                </DatesProvider>
              </Col>
              <Col span={12} pt={"20px"}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                ></Text>
                <Checkbox
                  checked={additionalPhoto}
                  label="Fotoğraf eklenecek mi?"
                  onChange={(event) =>
                    setAdditionalPhoto(event.currentTarget.checked)
                  }
                />
              </Col>
              <Col span={12}></Col>
            </Grid.Col>
          </Grid>

          <Space h={"20px"} />

          <Grid>
            <Grid.Col offsetMd={9} md={3} sm={12}>
              <Button
                style={{
                  paddingBottom: "11.5px",
                  paddingTop: "11.5px",
                  width: "100%",
                  borderRadius: rem(32),
                  backgroundColor: "#0097c4",
                  color: "#FFFFFF",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                }}
                type="submit"
                onClick={() => {
                  handleSave();
                }}
              >
                Kaydet
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Stack>
    </Paper>
  );
};

export default AddTaskCard;
