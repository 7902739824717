import {
  Anchor,
  Breadcrumbs,
  Flex,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { House } from "phosphor-react";

interface BreadCrumpProps {
  bcItems?: Array<BreadcrumbModel>;
}
export interface BreadcrumbModel {
  title: string | JSX.Element;
  href?: string;
}
export default function ImeceBreadCrump({ bcItems }: BreadCrumpProps) {
  const theme = useMantineTheme();
  return (
    <>
      {bcItems ? (
        <Breadcrumbs style={{ fontSize: "0rem" }}>
          {new Array<BreadcrumbModel>({
            title: (
              <Flex>
                <House size={rem(16)} />
                <Text pl={".3rem"}>Anasayfa</Text>
              </Flex>
            ),
            href: "/myFields",
          })
            .concat(bcItems)
            ?.map((item, index, arr) => (
              <>
                {item.href !== undefined && item.href !== "#" ? (
                  <Anchor href={process.env.PUBLIC_URL + item.href} key={index}>
                    <Text
                      mt={rem(2)}
                      key={index}
                      c={theme.colors.dark[4]}
                      size="xs"
                      variant="medium"
                    >
                      {item.title}
                    </Text>
                  </Anchor>
                ) : (
                  <Text
                    mt={rem(2)}
                    key={index}
                    c={theme.colors.dark[4]}
                    size="xs"
                    variant="medium"
                  >
                    {item.title}
                  </Text>
                )}
              </>
            ))}
        </Breadcrumbs>
      ) : null}
    </>
  );
}
