import {
  Box,
  Button,
  Checkbox,
  Col,
  Drawer,
  FileInput,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Space,
  Stack,
  Table,
  Text,
  Tooltip,
  rem,
  Image as MantineImage,
  Select,
  Textarea,
  Container,
  Spoiler,
  Input,
  Pagination,
} from "@mantine/core";
import { NumberInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import {
  Plus,
  File,
  CalendarBlank,
  Image as PhosporImage,
} from "@phosphor-icons/react";
import { useDisclosure } from "@mantine/hooks";

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";
import {
  GetFieldDetailInfoResponse,
  SeasonNote,
  SeasonNoteTitles,
} from "../../data/request/FieldDetailRequests";
import {
  addImagesFormData,
  addSeasonNotes,
  deleteNoteImage,
  deleteSeasonNote,
  fetchFieldInfo,
  fetchSeasonNoteTitle,
  updateSeasonNotesInfo,
} from "../../interactor/FieldDetailInteractor";
import { useSearchParams } from "react-router-dom";
import { Minus } from "phosphor-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

interface TransactionsProps {
  store?: GetFieldDetailInfoResponse;
  seasonId?: string;
}

class FileModel {
  id: string;
  file?: File;
  fileURL?: string;

  constructor(id: string, file?: File, fileURL?: string) {
    this.id = id;
    this.file = file;
    this.fileURL = fileURL;
  }
}

const TransactionsCard: React.FC<TransactionsProps> = ({ store, seasonId }) => {
  const dispatch = useAppDispatch();
  const changeSeasonNoteState = useAppSelector(
    (state) => state.updateSeasonNotesState.value
  );

  const [isCreateDrawer, setIsCreateDrawer] = useState(false);

  useEffect(() => {
    if (changeSeasonNoteState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));
    }
  }, [changeSeasonNoteState]);

  const [notes, setNotes] = useState<SeasonNote[]>();
  useEffect(() => {
    setNotes(store?.field.seasons.find((x) => x.id === seasonId)?.seasonNotes);
  }, [seasonId, store?.field.seasons]);

  useEffect(() => {
    setNotes(store?.field.seasons.find((x) => x.id === seasonId)?.seasonNotes);
  }, [seasonId, store?.field.seasons]);

  const [notesTitle, setNotesTitles] = useState<SeasonNoteTitles[]>();
  useEffect(() => {
    setNotes(store?.field.seasons.find((x) => x.id === seasonId)?.seasonNotes);
  }, [seasonId, store?.field.seasons]);

  useEffect(() => {
    if (queryParam) dispatch(fetchSeasonNoteTitle({ id: queryParam }));
  }, []);

  const [seasonNotesUpdateData, setSeasonNotesUpdateData] = useState<any>();
  const [seasonNotesAddData, setSeasonNotesAddData] = useState<any>();

  const [isNoteHidden, setIsNoteHidden] = useState(false);

  const [isImageClicked, setIsImageClicked] = useState(false);

  // Checkbox durumunu güncelleyen fonksiyon
  const handleNoteVisibilityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsNoteHidden(e.target.checked);
  };

  const ClickableImageTable = ({ note }: { note: any }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const handleImageClick = (imagePath: string) => {
      setSelectedImage(imagePath);
    };

    const handleCloseModal = () => {
      setSelectedImage(null);
    };
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [dateToPlant, setDateToPlant] = useState<Date | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [openNoteUpdateDrawer, setOpenNoteUpdateDrawer] = useState(false);
  const [openNoteAddDrawer, setOpenNoteAddDrawer] = useState(false);
  const [noteCreateImages, setNoteCreateImages] = useState<Array<FileModel>>(
    []
  );

  const createEmptyImages = (count: number) => {
    let returnArray: Array<FileModel> = [];

    for (let index = 0; index < count; index++) {
      returnArray.push(
        new FileModel(`image${index + 1}`, undefined, undefined)
      );
    }

    return returnArray;
  };

  const handleSeasonNotesDrawerOpen = (id: String) => {
    setOpenNoteUpdateDrawer(true);
    setIsCreateDrawer(false);
    let seasonnotedrawername = store?.field.seasons.find(
      (x) => x.id === seasonId?.valueOf()
    );
    if (seasonnotedrawername) {
      let noteForUpdate = seasonnotedrawername.seasonNotes.find(
        (x) => x.id === id
      );
      if (
        noteForUpdate?.title &&
        !getSeasonNoteTitleState?.data?.parameters
          ?.map((x: any) => x.value)
          .includes(noteForUpdate.title)
      ) {
        setSeasonNotesUpdateData({ ...noteForUpdate, value: "custom" });
      } else {
        setSeasonNotesUpdateData({
          ...noteForUpdate,
          value: getSeasonNoteTitleState?.data?.parameters?.find(
            (x: any) => x.value === noteForUpdate?.title
          )?.key,
        });
      }
      const drawerImages =
        seasonnotedrawername.seasonNotes
          .find((x) => x.id === id)
          ?.images?.map((m) => new FileModel(m.id, undefined, m.thumbPath)) ??
        [];
      createEmptyImages(3 - drawerImages.length);
      setNoteCreateImages(
        drawerImages.concat(createEmptyImages(3 - drawerImages.length))
      );
    }
    open();
  };

  const handleAddSeasonNotesDrawerOpen = () => {
    setIsCreateDrawer(true);
    setOpenNoteAddDrawer(true);
    setNoteCreateImages(createEmptyImages(3));
    open();
  };
  const [selectedNote, setSelectedNote] = useState<SeasonNote | null>(null);

  const [noteImages, setNoteImages] = useState<SeasonNote | null>(null);
  const handleRowClick = (note: SeasonNote) => {
    setSelectedNote(note);
  };

  interface AccountProps {
    store?: GetFieldDetailInfoResponse;
    seasonId?: string;
  }

  const deleteSeasonNoteState = useAppSelector(
    (state) => state.deleteSeasonNoteState.value
  );
  const [queryParams] = useSearchParams();
  const queryParam = queryParams.get("id");

  useEffect(() => {
    if (deleteSeasonNoteState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));
      toast.success("Not Silindi");
    }
    if (deleteSeasonNoteState?.success === false) {
      toast.error(deleteSeasonNoteState?.userMessage);
    }
  }, [deleteSeasonNoteState]);

  const handleEditDeleteNote = (noteId: string) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNote(noteId);
      }
    });
  };

  const deleteNote = (noteId: string) => {
    dispatch(deleteSeasonNote({ id: noteId }));
  };

  const deleteImageState = useAppSelector(
    (state) => state.deleteImagesState.value
  );

  useEffect(() => {
    if (deleteImageState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));

      dispatch(handleEditSeasonNotes);
    }
  }, [deleteImageState]);

  const deleteImage = (imageId: string) => {
    if (noteCreateImages) {
      const data = noteCreateImages.map((image) => {
        if (image.id === imageId) {
          return {
            id: imageId,
            file: undefined,
            fileURL: "",
          };
        }
        return image;
      });
      setNoteCreateImages(data);
    }
  };

  const NoteChangeInfoState = useAppSelector(
    (state) => state.updateSeasonNotesState.value
  );
  useEffect(() => {
    if (NoteChangeInfoState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));
      setSeasonNotesAddData(undefined);
    }
  }, [NoteChangeInfoState]);

  const handleEditSeasonNotes = () => {
    if (noteCreateImages) {
      noteCreateImages.map((image) => {
        if (image.fileURL === "") {
          dispatch(deleteNoteImage({ id: image.id }));
        }
      });
    }
    dispatch(updateSeasonNotesInfo({ ...seasonNotesUpdateData }));
  };

  const NoteAddState = useAppSelector(
    (state) => state.addSeasonNotesState.value
  );
  useEffect(() => {
    if (NoteAddState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));
      if (NoteAddState.data?.seasonNoteId) {
        handleAddNoteImageUpload(NoteAddState.data?.seasonNoteId);
      }
    }
  }, [NoteAddState]);

  const handleCreateNote = () => {
    if (
      seasonNotesAddData?.description !== "" &&
      seasonNotesAddData?.description !== undefined
    ) {
      handleAddSeasonNotes();
    } else {
      toast.warning("Açıklama alanı zorunludur.");
    }
  };

  const handleAddSeasonNotes = () => {
    dispatch(
      addSeasonNotes({
        seasonId: seasonId,
        seasonNote: { ...seasonNotesAddData },
      })
    );
  };

  const getSeasonNoteTitleState = useAppSelector(
    (state) => state.seasonNoteTitleState.value
  );

  const addSeasonNotesState = useAppSelector(
    (state) => state.addSeasonNotesState.value
  );

  const SeasonNotesState = useAppSelector(
    (state) => state.addSeasonsState.value
  );

  const uploadImagesState = useAppSelector(
    (state: any) => state.addImagesApiState.value
  );

  const updateSeasonNotesState = useAppSelector(
    (state) => state.updateSeasonNotesState.value
  );

  useEffect(() => {
    if (uploadImagesState && uploadImagesState.length > 0) {
      if (isCreateDrawer) {
        dispatch(fetchFieldInfo({ id: queryParam ?? "" }));
      } else {
        handleEditSeasonNotes();
      }
      setNoteCreateImages(uploadImagesState);
    }
  }, [uploadImagesState]);

  useEffect(() => {
    if (addSeasonNotesState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));
      toast.success("Not Eklendi");
      setSeasonNotesAddData(undefined);
      setNoteCreateImages([]);
      setOpenNoteAddDrawer(false);
    }
  }, [addSeasonNotesState]);

  useEffect(() => {
    if (updateSeasonNotesState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));
      toast.success("Not Düzenlendi");
      setOpenNoteUpdateDrawer(false);
    } else {
      toast.error(updateSeasonNotesState?.userMessage);
    }
  }, [updateSeasonNotesState]);

  const handleEditNoteWarning = (noteId: string) => {
    if (
      seasonNotesUpdateData?.description !== "" &&
      seasonNotesUpdateData?.description !== undefined
    ) {
      handleImageUpload(noteId);
    } else {
      toast.warning("Açıklama alanı zorunludur.");
    }
  };

  const handleImageUpload = (id: string) => {
    const media = noteCreateImages
      .filter((x) => x.file !== undefined)
      .map((y) => y.file);
    if (media.length > 0) {
      const formData = new FormData();
      media.forEach((x) => {
        if (x) {
          formData.append("media", x);
        }
      });

      // formData.append("media", media);

      formData.append("id", id);
      dispatch(addImagesFormData(formData));
    } else {
      handleEditSeasonNotes();
    }
  };

  const handleAddNoteImageUpload = (id: string) => {
    const media = noteCreateImages
      .filter((x) => x.file !== undefined)
      .map((y) => y.file);
    if (media.length > 0) {
      const formData = new FormData();
      media.forEach((x) => {
        if (x) {
          formData.append("media", x);
        }
      });

      // formData.append("media", media);

      formData.append("id", id);
      dispatch(addImagesFormData(formData));
    }
  };

  const handleFileChange = (item: FileModel, file: File) => {
    if (
      file?.name.includes(".jpeg") ||
      file?.name.includes(".png") ||
      file?.name.includes(".jpg") ||
      file?.name.includes(".heic")
    ) {
      var reader = new FileReader();

      reader.onload = function (e: any) {
        // get loaded data and render thumbnail.
        setNoteCreateImages(
          noteCreateImages.map((note) => {
            if (note.id === item.id) {
              return { ...note, fileURL: e.target.result, file: file };
            }
            return note;
          })
        );
      };

      // read the image file as a data URL.
      reader.readAsDataURL(new Blob([file], { type: "image/jpeg" }));
    } else {
      toast.warning("Görselin uzantısı .jpeg, .heic, .jpg veya .png olabilir.");
    }
  };

  const handleOnLoad = (item: FileModel, file: File) => {
    var newFiles = [...noteCreateImages];
    if (noteCreateImages.find((f) => f.id === item.id)?.file !== undefined) {
      newFiles.push({
        file: file,
        id: item.id,
      });
      setNoteCreateImages(newFiles);
    } else {
      noteCreateImages.map((note) => {
        if (note.id === item.id) {
          return { ...note, file: file };
        }
        return note;
      });
      // setNoteCreateImages(newFiles.filter((x) => x.index !== index));
    }
  };

  const [choosenImage, setChosenImage] = useState<string>();

  const [noteId, setNoteId] = useState<string>();

  function handleImageClick(imagePath: string): void {
    setChosenImage(imagePath);
    setIsImageClicked(true);
  }

  function handleModalClosedClick() {
    setIsImageClicked(false);
  }

  const amountLabelText = (data: any) => {
    switch (data?.title) {
      case "custom":
        return "";
      case "Diğer Gelir":
        return "Gelirinizi girebilirsiniz...";
      case "AkaryakıtAkaryakıt":
        return "Giderinizi girebilirsiniz...";
      case "Diğer Gider":
        return "Giderinizi girebilirsiniz...";
      case "Gübreleme":
        return "Giderinizi girebilirsiniz...";
      case "İlaçlama":
        return "Giderinizi girebilirsiniz...";
      case "Akaryakıt":
        return "Giderinizi girebilirsiniz...";
      case "Kira":
        return "Giderinizi girebilirsiniz...";
      case "Tohum":
        return "Giderinizi girebilirsiniz...";
      case "Sulama":
        return "Giderinizi girebilirsiniz...";
      case "İşçilik":
        return "Giderinizi girebilirsiniz...";
      case "Satış":
        return "Gelirinizi girebilirsiniz...";
      default:
        return "";
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [notesPagged, setNotesPagged] = useState<SeasonNote[]>([]);
  const recordsPerPage = 10;

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalOutcome, setTotalOutcome] = useState(0);

  let indexOfLastRecord = 0;
  let indexOfFirstRecord = 0;

  useEffect(() => {
    const indexOfFirstRecord = (currentPage - 1) * recordsPerPage;
    const indexOfLastRecord = indexOfFirstRecord + recordsPerPage;

    if (notes) {
      setNotesPagged(
        notes.slice().reverse().slice(indexOfFirstRecord, indexOfLastRecord)
      );
      setTotalPages(Math.ceil(notes.length / recordsPerPage));

      const positiveAmountNotes = notes
        .filter((note) => note.amount && note.amount > 0)
        .reduce((total, note) => total + (note.amount || 0), 0);

      const negativeAmountNotes = notes
        .filter((note) => note.amount && note.amount < 0)
        .reduce((total, note) => total + (note.amount || 0), 0);

      setTotalOutcome(negativeAmountNotes);
      setTotalIncome(positiveAmountNotes);
    }
  }, [notes, currentPage]);

  return (
    <>
      <Modal
        size="xL"
        opened={isImageClicked}
        onClose={handleModalClosedClick}
        zIndex={1000}
      >
        <MantineImage radius="md" src={choosenImage} />
      </Modal>
      <Paper
        p={20}
        h={"%100"}
        className="widgets"
        style={{
          borderBottomLeftRadius: "23px",
          borderBottomRightRadius: "23px",
        }}
        bg="white"
      >
        <Group>
          {store?.field.seasons.find((x) => x.id === seasonId)?.seasonClosed ===
          false ? (
            <Plus
              size={rem(24)}
              color="white"
              style={{
                borderRadius: rem(24),
                padding: rem(3),
                backgroundColor: "#0097C4",
              }}
            />
          ) : null}

          <Drawer.Root
            opened={openNoteAddDrawer}
            onClose={() => setOpenNoteAddDrawer(false)}
            position="right"
          >
            <Drawer.Content
              style={{ paddingLeft: "40px", paddingRight: "40px", width: 1000 }}
            >
              <Drawer.Header>
                <Drawer.Title
                  style={{ font: "Poppins-Light", fontSize: "28px" }}
                >
                  İşlem Ekle
                </Drawer.Title>
                <Drawer.CloseButton />
              </Drawer.Header>

              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Konu
                </Text>

                <Select
                  placeholder="Not konusu seçin"
                  value={seasonNotesAddData?.value}
                  onChange={(e) => {
                    if (e === "custom") {
                      setSeasonNotesAddData({
                        ...seasonNotesAddData,
                        value: e,
                        title: "",
                      });
                    } else
                      setSeasonNotesAddData({
                        ...seasonNotesAddData,
                        title: getSeasonNoteTitleState?.data?.parameters?.find(
                          (x: any) => x.key === e
                        ).value,
                        value: e,
                      });
                  }}
                  data={
                    getSeasonNoteTitleState?.data?.parameters?.map(
                      (x: any) => ({
                        value: x.key,
                        label: x.value,
                      })
                    ) || []
                  }
                />
              </Col>
              {seasonNotesAddData?.value === "custom" && (
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Konu
                  </Text>
                  <Textarea
                    required={true}
                    placeholder="Konu yazın"
                    value={seasonNotesAddData?.title}
                    onChange={(e) => {
                      setSeasonNotesAddData({
                        ...seasonNotesAddData,
                        title: e.currentTarget.value,
                      });
                    }}
                    maxLength={64}
                  />
                </Col>
              )}
              {seasonNotesAddData?.value !== "custom" &&
                seasonNotesAddData?.value && (
                  <Col span={12}>
                    <Text
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins-Medium",
                        color: "#136583",
                      }}
                    >
                      &#8378; &nbsp;{amountLabelText(seasonNotesAddData)}
                    </Text>
                    <NumberInput
                      required={true}
                      placeholder="Tutar giriniz"
                      value={seasonNotesAddData?.amount}
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          ",",
                          "-",
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                          "Tab",
                        ];
                        if (
                          !allowedKeys.includes(e.key) &&
                          !/^[0-9]$/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const floatValue = parseFloat(
                          e.toString().replace(",", ".")
                        );
                        setSeasonNotesAddData({
                          ...seasonNotesAddData,
                          amount: floatValue,
                        });
                      }}
                      step={0.01}
                      precision={2}
                      decimalSeparator=","
                      thousandsSeparator="."
                    />
                  </Col>
                )}
              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Açıklama
                </Text>
                <Textarea
                  required={true}
                  placeholder="Açıklama girin"
                  value={seasonNotesAddData?.description}
                  autosize
                  sx={{
                    textarea: {
                      minHeight: "250px",
                    },
                  }}
                  onChange={(e) => {
                    setSeasonNotesAddData({
                      ...seasonNotesAddData,
                      description: e.currentTarget.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                ></Text>
                <Checkbox
                  checked={seasonNotesAddData?.isPrivate}
                  label="Notu Üreticiden Gizle"
                  onChange={(event) =>
                    setSeasonNotesAddData({
                      ...seasonNotesAddData,
                      isPrivate: event.currentTarget.checked,
                    })
                  }
                />
              </Col>

              {/* Add three image upload sections below the Açıklama input */}
              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                    marginTop: "20px", // Add margin for separation
                  }}
                ></Text>

                {/* First Image Upload */}
                <Space h="10px" />

                <Flex direction="row" align={"center"}>
                  {noteCreateImages.map((item, index) => (
                    <Box
                      key={item.id}
                      style={{
                        width: "100px",
                        height: "100px",
                        border: "1px solid #0097C4",
                        borderRadius: "8px",
                        overflow: "hidden",
                        position: "relative",
                        marginRight: "20px", // Adjust the margin as needed
                      }}
                    >
                      <FileInput
                        onLoad={(e) => {}}
                        accept="jpeg, png, heic"
                        id={`fileInput${item.id}`}
                        value={item.file}
                        style={{ display: "none" }}
                        onChange={(file: File) => handleFileChange(item, file)}
                        w={"100px"}
                        h={"300px"}
                      />

                      <MantineImage
                        onClick={() => handleImageClick(item.fileURL ?? "")}
                        radius="md"
                        src={item.fileURL}
                        id={`fileImage${item.id}`}
                      />
                      {item.fileURL && (
                        <Minus
                          key={index}
                          size={rem(24)}
                          color="white"
                          style={{
                            borderRadius: rem(24),
                            padding: rem(3),
                            backgroundColor: "red",
                            position: "absolute",
                            top: rem(5),
                            right: rem(5),
                          }}
                          onClick={() => deleteImage(item.id)} // Assuming imagePath is a unique identifier for each image
                        />
                      )}
                      <Plus
                        size={20} // İhtiyaca göre boyutu ayarlayın
                        style={{
                          position: "absolute",
                          bottom: "5px", // İhtiyaca göre alttan mesafeyi ayarlayın
                          left: "50%",
                          transform: "translateX(-50%)",
                          color: "#0097C4",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const fileInput = document.getElementById(
                            `fileInput${item.id}`
                          );
                          fileInput?.click(); // Dosya girişi seçimini tetikle
                        }}
                      />
                    </Box>
                  ))}
                </Flex>
              </Col>

              <Col span={12}>
                <Space h="10px" />
                <Col>
                  <Button
                    onClick={handleCreateNote}
                    style={{
                      backgroundColor: "#0097c4",
                      paddingBottom: "11.5px",
                      paddingTop: "11.5px",
                      width: "100%",
                      borderRadius: 32,
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: "12px",
                      alignItems: "center",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    Kaydet
                  </Button>

                  <Space h="20px" />

                  {/* <Button
                onClick={ () => deleteNote(note.id)}
                  style={{
                    paddingBottom: "11.5px",
                    paddingTop: "11.5px",
                    width: "100%",
                    borderRadius: 32,
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    alignItems: "center",
                    textAlign: "center",
                    border: "0.5px solid #000000",
                    fontFamily: "Poppins-Light",
                    fontSize: "12px",
                  }}
                >
                  Notu Sil
                </Button> */}
                </Col>
              </Col>
            </Drawer.Content>
          </Drawer.Root>

          {store?.field.seasons.find((x) => x.id === seasonId)?.seasonClosed ===
          false ? (
            <Button
              style={{
                borderRadius: 32,
                backgroundColor: "#FFFFFF",
                color: "#0097C4",
                fontFamily: "Poppins-Medium",
                fontSize: rem(14),
                textAlign: "center",
              }}
              onClick={handleAddSeasonNotesDrawerOpen}
            >
              Yeni İşlem Ekle
            </Button>
          ) : null}
        </Group>

        <Box>
          <Box style={{ display: "flex" }}>
            <Text style={{ fontWeight: "500", fontSize: "14px" }}>
              Toplam Gelir :
            </Text>{" "}
            <Text style={{ color: "green", fontSize: "14px" }}>
              &nbsp;
              {Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
              }).format(totalIncome ?? 0)}
            </Text>
          </Box>
          <Box style={{ display: "flex" }}>
            <Text style={{ fontWeight: "500", fontSize: "14px" }}>
              Toplam Gider :
            </Text>
            <Text style={{ color: "red", fontSize: "14px" }}>
              &nbsp;
              {Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
              }).format(totalOutcome ?? 0)}
            </Text>
          </Box>
          <Box style={{ display: "flex" }}>
            <Text style={{ fontWeight: "500", fontSize: "14px" }}>
              Net Gelir :
            </Text>{" "}
            <Text
              style={{
                fontSize: "14px",
                color: totalOutcome + totalIncome >= 0 ? "green" : "red",
              }}
            >
              &nbsp;
              {Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
              }).format(totalIncome + totalOutcome)}
            </Text>
          </Box>
        </Box>

        <Table
          style={{
            marginTop: "20px",
            border: "1px solid #0097C4",
            borderRadius: "20px", // Ya da başka bir değer deneyebilirsiniz
            overflow: "hidden",
          }}
        >
          <thead
            style={{
              backgroundColor: "#eef0f8",
              font: "20px",
              color: "Black",
              alignItems: "center",
            }}
          >
            <tr>
              <th
                style={{
                  color: "black",
                  textAlign: "center",
                  borderRight: "1px solid #136583",
                  padding: "10px",
                }}
              >
                Tarih
              </th>
              <th
                style={{
                  color: "black",
                  textAlign: "center",
                  borderRight: "1px solid #136583",
                  padding: "10px",
                }}
              >
                Konu
              </th>
              <th
                style={{
                  color: "black",
                  textAlign: "center",
                  borderRight: "1px solid #136583",
                  padding: "10px",
                }}
              >
                Tutar
              </th>
              <th
                style={{
                  color: "black",
                  textAlign: "center",
                  borderRight: "1px solid #136583",
                  padding: "10px",
                }}
              >
                Açıklama
              </th>
              <th
                style={{
                  color: "black",
                  textAlign: "center",
                  borderRight: "1px solid #136583",
                  padding: "10px",
                }}
              >
                Resim
              </th>
              {store?.field.seasons.find((x) => x.id === seasonId)
                ?.seasonClosed === false ? (
                <th
                  style={{
                    color: "black",
                    textAlign: "center",
                    borderRight: "1px solid #136583",
                    padding: "10px",
                  }}
                >
                  Düzenle & Sil
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody
            style={{ fontFamily: "Poppins", font: "12px", color: "#000000" }}
          >
            {notesPagged &&
              notesPagged.map((note) => (
                <tr key={note.id}>
                  <td
                    style={{
                      textAlign: "left",
                      borderRight: "1px solid #136583",
                      padding: "10px",
                    }}
                  >
                    {note?.noteDate
                      ? new Date(note?.noteDate).toLocaleDateString("tr-TR")
                      : "Not Available"}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      borderRight: "1px solid #136583",
                      padding: "10px",
                      maxHeight: "72px",
                      cursor: "pointer",
                      whiteSpace: "pre-wrap",
                      paddingRight: rem(10),
                      maxWidth: "200px",
                    }}
                  >
                    {note?.title}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      borderRight: "1px solid #136583",
                      padding: "10px",
                      maxHeight: "72px",
                      cursor: "pointer",
                      whiteSpace: "pre-wrap",
                      paddingRight: rem(10),
                      maxWidth: "200px",
                    }}
                  >
                    {note?.amount
                      ? Intl.NumberFormat("tr-TR", {
                          style: "currency",
                          currency: "TRY",
                        }).format(note.amount ?? 0)
                      : ""}
                  </td>

                  <td
                    style={{
                      borderRight: "1px solid #136583",
                      cursor: "pointer",
                      whiteSpace: "pretty",
                      textAlign: "left",
                      maxHeight: "48px",
                      overflow: "hidden",
                      maxWidth: "200px", // Genişliği sınırlayarak diğerlerini bozmadan görüntülenmesini sağlar
                    }}
                  >
                    <Tooltip label={note.description}>
                      <div
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div onClick={() => handleRowClick(note)}>
                          {note.description.length > 20 ? (
                            <>
                              {note.description.replaceAll("\n", " ").length <
                              100
                                ? note.description
                                : note.description.substring(0, 100)}

                              <span
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                ...Devamı
                              </span>
                            </>
                          ) : (
                            note.description
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      borderRight: "1px solid #136583",
                      padding: "10px",
                    }}
                  >
                    {note && (
                      <>
                        <Grid>
                          <Grid.Col span={12}>
                            <Flex
                              direction="row"
                              align="center"
                              justify="center"
                            >
                              {note &&
                                note.images &&
                                note.images.map((image, index) => (
                                  <Box maw={30} key={index}>
                                    <MantineImage
                                      radius={"md"}
                                      src={image.imagePath}
                                      alt={`Selected note ${index}`}
                                      fit="cover"
                                      onClick={() =>
                                        handleImageClick(image.imagePath)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Box>
                                ))}
                              <Text fw={"400"}></Text>
                            </Flex>
                          </Grid.Col>
                        </Grid>
                      </>
                    )}
                  </td>

                  <Drawer.Root
                    opened={openNoteUpdateDrawer}
                    onClose={() => setOpenNoteUpdateDrawer(false)}
                    position="right"
                  >
                    <Drawer.Content
                      style={{
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        width: 1000,
                      }}
                    >
                      <Drawer.Header>
                        <Drawer.Title
                          style={{ font: "Poppins-Light", fontSize: "28px" }}
                        >
                          Sezon Notları Düzenle
                        </Drawer.Title>

                        <Drawer.CloseButton />
                      </Drawer.Header>

                      <Col span={12}>
                        <Text
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins-Medium",
                            color: "#136583",
                          }}
                        >
                          {" "}
                          Tarih
                        </Text>
                        <DateInput
                          valueFormat="DD.MM.YYYY"
                          readOnly
                          rightSection={
                            <CalendarBlank size={16} color="#6c757d" />
                          }
                          value={
                            note?.noteDate ? new Date(note?.noteDate) : null // Varsayılan değer
                          }
                          onChange={(value) => {
                            if (value instanceof Date) {
                              setDateToPlant(value);
                            } else {
                              setDateToPlant(null); // Eğer value null ya da undefined ise
                            }
                          }}
                        />
                      </Col>

                      <Col span={12}>
                        <Text
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins-Medium",
                            color: "#136583",
                          }}
                        >
                          {" "}
                          Konu
                        </Text>
                        <Select
                          placeholder="Not konusu seçin"
                          value={seasonNotesUpdateData?.value}
                          onChange={(e) => {
                            if (e === "custom") {
                              setSeasonNotesUpdateData({
                                ...seasonNotesUpdateData,
                                value: e,
                                title: "",
                              });
                            } else
                              setSeasonNotesUpdateData({
                                ...seasonNotesUpdateData,
                                title:
                                  getSeasonNoteTitleState?.data?.parameters?.find(
                                    (x: any) => x.key === e
                                  ).value,
                                value: e,
                              });
                          }}
                          data={
                            getSeasonNoteTitleState?.data?.parameters?.map(
                              (x: any) => ({
                                value: x.key,
                                label: x.value,
                              })
                            ) || []
                          }
                        />
                      </Col>
                      {seasonNotesUpdateData?.value === "custom" && (
                        <Col span={12}>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontFamily: "Poppins-Medium",
                              color: "#136583",
                            }}
                          >
                            {" "}
                            Konu
                          </Text>
                          <Textarea
                            required={true}
                            placeholder="Konu yazın"
                            value={seasonNotesUpdateData?.title}
                            onChange={(e) => {
                              setSeasonNotesUpdateData({
                                ...seasonNotesUpdateData,
                                title: e.currentTarget.value,
                              });
                            }}
                          />
                        </Col>
                      )}
                      {seasonNotesUpdateData?.value !== "custom" &&
                        seasonNotesUpdateData?.value && (
                          <Col span={12}>
                            <Text
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins-Medium",
                                color: "#136583",
                              }}
                            >
                              &#8378; &nbsp;
                              {amountLabelText(seasonNotesUpdateData)}
                            </Text>
                            <NumberInput
                              required={true}
                              placeholder="Tutar giriniz"
                              value={seasonNotesUpdateData?.amount}
                              onKeyDown={(e) => {
                                const allowedKeys = [
                                  ",",
                                  "-",
                                  "Backspace",
                                  "ArrowLeft",
                                  "ArrowRight",
                                  "Delete",
                                  "Tab",
                                ];
                                if (
                                  !allowedKeys.includes(e.key) &&
                                  !/^[0-9]$/.test(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                const floatValue = parseFloat(
                                  e.toString().replace(",", ".")
                                );
                                setSeasonNotesUpdateData({
                                  ...seasonNotesUpdateData,
                                  amount: floatValue,
                                });
                              }}
                              step={0.01}
                              precision={2}
                              decimalSeparator=","
                              thousandsSeparator="."
                            />
                          </Col>
                        )}
                      <Col span={12}>
                        <Text
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins-Medium",
                            color: "#136583",
                          }}
                        >
                          {" "}
                          Açıklama
                        </Text>
                        <Textarea
                          required={true}
                          placeholder={note.description}
                          value={seasonNotesUpdateData?.description}
                          autosize
                          sx={{
                            textarea: {
                              minHeight: "250px",
                            },
                          }}
                          onChange={(e) => {
                            setSeasonNotesUpdateData({
                              ...seasonNotesUpdateData,
                              description: e.currentTarget.value,
                            });
                          }}
                        />
                      </Col>

                      <Col span={12}>
                        <Text
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins-Medium",
                            color: "#136583",
                          }}
                        ></Text>
                        <Checkbox
                          checked={seasonNotesUpdateData?.isPrivate}
                          label="Notu Üreticiden Gizle"
                          onChange={(event) => {
                            setSeasonNotesUpdateData({
                              ...seasonNotesUpdateData,
                              isPrivate: event.currentTarget.checked,
                            });
                          }}
                        />
                      </Col>

                      {/* Add three image upload sections below the Açıklama input */}
                      <Col span={12}>
                        <Text
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins-Medium",
                            color: "#136583",
                            marginTop: "20px", // Add margin for separation
                          }}
                        ></Text>

                        <Space h="10px" />

                        <Flex direction="row">
                          {noteCreateImages.map((item, index) => (
                            <Box
                              key={item.id}
                              style={{
                                width: "100px",
                                height: "100px",
                                border: "1px solid #0097C4",
                                borderRadius: "8px",
                                overflow: "hidden",
                                position: "relative",
                                marginRight: "20px", // Adjust the margin as needed
                              }}
                              // onClick={() => handleImageClick(item.fileURL)}
                            >
                              <FileInput
                                accept="image/jpeg, image/png, image/heic"
                                id={`fileInput${item.id}`}
                                value={item.file}
                                style={{ display: "none" }}
                                onChange={(file: File) =>
                                  handleFileChange(item, file)
                                }
                                w={"100px"}
                                h={"300px"}
                              />

                              <MantineImage
                                onClick={() =>
                                  handleImageClick(item.fileURL ?? "")
                                }
                                radius="md"
                                src={item.fileURL}
                                id={`fileImage${item.id}`}
                              />

                              {item.fileURL && (
                                <Minus
                                  key={index} // Rendering dynamic lists in React requires a unique key
                                  size={rem(24)}
                                  color="white"
                                  style={{
                                    borderRadius: rem(24),
                                    padding: rem(3),
                                    backgroundColor: "red",
                                    position: "absolute",
                                    top: rem(5), // Adjust the distance from the top
                                    right: rem(5), // Adjust the distance from the right
                                  }}
                                  onClick={() => deleteImage(item.id)}
                                />
                              )}

                              <Plus
                                size={20} // İhtiyaca göre boyutu ayarlayın
                                style={{
                                  position: "absolute",
                                  bottom: "5px", // İhtiyaca göre alttan mesafeyi ayarlayın
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  color: "#0097C4",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const fileInput = document.getElementById(
                                    `fileInput${item.id}`
                                  );
                                  fileInput?.click(); // Dosya girişi seçimini tetikle
                                }}
                              />
                            </Box>
                          ))}
                        </Flex>
                      </Col>

                      <Col span={12}>
                        <Space h="10px" />

                        <Col>
                          <Button
                            style={{
                              backgroundColor: "#0097c4",
                              paddingBottom: "11.5px",
                              paddingTop: "11.5px",
                              width: "100%",
                              borderRadius: 32,
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "12px",
                              alignItems: "center",
                              fontFamily: "Poppins-Medium",
                            }}
                            onClick={() => handleEditNoteWarning(note.id)}
                          >
                            Güncelle
                          </Button>

                          <Space h="20px" />

                          <Button
                            onClick={() => handleEditDeleteNote(note.id)}
                            style={{
                              paddingBottom: "11.5px",
                              paddingTop: "11.5px",
                              width: "100%",
                              borderRadius: 32,
                              backgroundColor: "#FFFFFF",
                              color: "#000000",
                              alignItems: "center",
                              textAlign: "center",
                              border: "0.5px solid #000000",
                              fontFamily: "Poppins-Light",
                              fontSize: "12px",
                            }}
                          >
                            Notu Sil
                          </Button>
                        </Col>
                      </Col>
                    </Drawer.Content>
                  </Drawer.Root>

                  {store?.field.seasons.find((x) => x.id === seasonId)
                    ?.seasonClosed === false ? (
                    <td style={{ textAlign: "center" }}>
                      {store?.field.seasons
                        .find((x) => x.id === seasonId)

                        ?.seasonNotes.find((x) => x.id === note.id)
                        ?.editable === true ? (
                        <Button
                          onClick={() => handleSeasonNotesDrawerOpen(note.id)}
                          style={{
                            borderRadius: 32,
                            backgroundColor: "#FFFFFF",
                            color: "#000000",
                            border: "0.5px solid #000000",
                            fontFamily: "Poppins-Light",
                            fontSize: rem(12),
                            textAlign: "center",
                          }}
                        >
                          Düzenle
                        </Button>
                      ) : null}
                      {store?.field.seasons.find((x) => x.id === seasonId)
                        ?.seasonClosed === false &&
                      store?.field.seasons
                        .find((x) => x.id === seasonId)

                        ?.seasonNotes.find((x) => x.id === note.id)
                        ?.editable === true ? (
                        <Button
                          onClick={() => handleEditDeleteNote(note.id)}
                          style={{
                            marginLeft: "20px",
                            borderRadius: 32,
                            backgroundColor: "#FFFFFF",
                            color: "#000000",
                            border: "0.5px solid #000000",
                            fontFamily: "Poppins-Light",
                            fontSize: rem(12),
                            textAlign: "center",
                          }}
                        >
                          Sil
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleRowClick(note)}
                          style={{
                            marginLeft: "20px",
                            borderRadius: 32,
                            backgroundColor: "#FFFFFF",
                            color: "#000000",
                            border: "0.5px solid #000000",
                            fontFamily: "Poppins-Light",
                            fontSize: rem(12),
                            textAlign: "center",
                          }}
                        >
                          Görüntüle
                        </Button>
                      )}
                    </td>
                  ) : null}
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={6} style={{ padding: "5px" }}>
                <Pagination
                  total={totalPages}
                  onChange={setCurrentPage}
                  siblings={3}
                  defaultValue={1}
                />
              </td>
            </tr>
          </tfoot>
        </Table>

        {notes?.length === 0 && (
          <Container fluid pt={16}>
            <Grid grow>
              <Grid.Col span={12}>
                <Flex justify={"center"} align={"center"}>
                  <Text fz={14} style={{ fontFamily: "Poppins-Medium" }}>
                    Sezona ait notunuz bulunmamaktadır
                  </Text>
                </Flex>
              </Grid.Col>
            </Grid>
          </Container>
        )}

        <Modal
          opened={selectedNote !== null}
          onClose={() => setSelectedNote(null)}
          className="custom-modal"
          title="Not Detayları"
          centered
          size={"lg"}
          withCloseButton
          styles={{
            content: {
              borderRadius: "2rem",
              padding: ".8rem",
            },
          }}
          sx={{
            ".mantine-Modal-title": {
              fontSize: "20px !important",
              fontWeight: "bold",
            },
          }}
        >
          {selectedNote && (
            <>
              <Grid>
                <Grid.Col span={12}>
                  <Group grow spacing={"xs"}>
                    <Text fw={"500"} size={rem(22)}>
                      {selectedNote.title}
                    </Text>

                    <Flex align="left" direction="column">
                      <Text fw={"500"} size={rem(15)}>
                        {selectedNote?.noteDate
                          ? new Date(selectedNote?.noteDate).toLocaleDateString(
                              "tr-TR"
                            )
                          : "Not Available"}
                      </Text>
                      <Flex align="center">
                        <Text>
                          {selectedNote.corporateOwner?.employeeName ??
                            store?.field.farmerName ??
                            ""}
                        </Text>
                        <Text> - </Text>
                        <Text>
                          {selectedNote.corporateOwner?.phoneNumber
                            ? selectedNote.corporateOwner.phoneNumber.slice(2)
                            : store?.field.farmerPhoneNumber
                            ? store?.field.farmerPhoneNumber.slice(2)
                            : ""}
                        </Text>
                        <Text> - </Text>
                        <Text>
                          {selectedNote.corporateOwner?.authorityType === 1
                            ? "Yetkili"
                            : selectedNote.corporateOwner?.authorityType === 2
                            ? "Mühendis"
                            : selectedNote.corporateOwner?.authorityType === 3
                            ? "Üretici"
                            : "Üretici"}
                        </Text>
                      </Flex>
                    </Flex>
                  </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Stack>
                    {selectedNote.description ? (
                      selectedNote.description
                        .split("\n")
                        .map((line, index) => (
                          <Text key={index} fw={"400"}>
                            {line}
                          </Text>
                        ))
                    ) : (
                      <Text fw={"400"}>Açıklama bulunamadı.</Text>
                    )}
                  </Stack>
                </Grid.Col>
              </Grid>
            </>
          )}
        </Modal>
      </Paper>
    </>
  );
};

export default TransactionsCard;
