import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import {
	AgreementFetchRequest,
	AgreementAcceptRequest,
} from "../data/request/AgreementRequest";
import {
	AgreementFetchResponse,
	AgreementAcceptResponse,
} from "../data/response/AgreementResponse";
import { ServiceState } from "../../common/data/ServiceState";

export const fetchAgreement = createAsyncThunk(
	"agreement/fetchAgreement",
	async (request: AgreementFetchRequest, thunkAPI) => {
		try {
			const response =
				await AxiosHelper.getInstance().get<AgreementFetchResponse>(
					"/corporate/agreement",
				);
			return response.data;
		} catch (error) {
			console.error(error);
            return thunkAPI.rejectWithValue("Failed to fetch agreement.");
		}
	}
);

export const acceptAgreement = createAsyncThunk(
	"agreement/acceptAgreement",
	async (request: AgreementAcceptRequest, thunkAPI) => {
		try {
			const response =
				await AxiosHelper.getInstance().post<AgreementAcceptResponse>(
					"/corporate/agreement",
					{ id: request.agreementId },
				);
                if (response.data.success) {
                    const data = response.data.data;

                    if (data) {
                      if (data.token) {
                        localStorage.setItem("token", data.token.accessToken || "");
                        localStorage.setItem("refreshToken", data.token.refreshToken || "");
                      }
                      localStorage.setItem("corporateId", data.corporateId || "");
                      localStorage.setItem("corporateName", data.corporateName || "");
                      localStorage.setItem("employeeName", data.employeeName || "");
                      localStorage.setItem("authorityType", (data.authorityType || 1).toString());
                      localStorage.setItem("subscriptionType", (data.subscriptionType || 1).toString());
                    }
                  }
			return response.data;
		} catch (error) {
			console.error(error);
            return thunkAPI.rejectWithValue("Failed to accept agreement.");
		}
	}
);

const initialFetchState: ServiceState<AgreementFetchResponse> = {};
const initialAcceptState: ServiceState<AgreementAcceptResponse> = {};

export const AgreementFetchSlice = createSlice({
	name: "agreement/fetch",
	initialState: initialFetchState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAgreement.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchAgreement.fulfilled, (state, action) => {
				state.isLoading = false;
				state.value = action.payload;
			})
			.addCase(fetchAgreement.rejected, (state, action) => {
				state.isLoading = false;
				// TODO : error
			});
	},
});

export const AgreementAcceptSlice = createSlice({
	name: "agreement/accept",
	initialState: initialAcceptState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(acceptAgreement.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(acceptAgreement.fulfilled, (state, action) => {
				state.isLoading = false;
				state.value = action.payload;
			})
			.addCase(acceptAgreement.rejected, (state, action) => {
				state.isLoading = false;
				// TODO : error
			});
	},
});
