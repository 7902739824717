import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OTPRequest } from "../data/request/LoginRequests";
import AxiosHelper from "../../common/util/AxiosHelper";
import { OTPResponse } from "../data/response/LoginResponses";
import { ServiceState } from "../../common/data/ServiceState";
import { LoginDto } from "../data/dto/LoginDto";

export const OTPService = createAsyncThunk(
  "login/service/confirm",
  async (request: OTPRequest, thunkAPI) => {
    try {
      const otpResponse = await AxiosHelper.getInstance().post<OTPResponse>(
        "corporate/auth/confirm",
        request
      );

      localStorage.setItem(
        "token",
        otpResponse.data.data?.token?.accessToken ?? ""
      );

      localStorage.setItem(
        "refreshToken",
        otpResponse.data.data?.token?.refreshToken ?? ""
      );

      localStorage.setItem(
        "corporateId",
        otpResponse.data?.data?.corporateId ?? ""
      );

      localStorage.setItem(
        "corporateName",
        otpResponse.data?.data?.corporateName ?? ""
      );

      localStorage.setItem(
        "employeeName",
        otpResponse.data?.data?.employeeName ?? ""
      );

      localStorage.setItem(
        "authorityType",
        (otpResponse.data?.data?.authorityType ?? 1).toString()
      );

      localStorage.setItem(
        "subscriptionType",
        (otpResponse.data?.data?.subscriptionType ?? 1).toString()
      );

      return otpResponse.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<OTPResponse> = {};

const OTPSlice = createSlice({
  name: "login/login",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(OTPService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(OTPService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(OTPService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default OTPSlice.reducer;
