import React, { useEffect, useState } from "react";
import {
  Tabs,
  Paper,
  Grid,
  Col,
  Text,
  Select,
  LoadingOverlay,
  TextInput,
  Button,
} from "@mantine/core";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import { useSelector } from "react-redux";
import { RootState } from "../../connector/ModuleStore";
import { useAppDispatch } from "../../connector/ModuleHook";
import {
  AnswerReportFormExcelService,
  AnswerReportFormService,
  FormListService,
} from "../../interractor/SurveyInterractor";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { IconSearch, IconSortDescending } from "@tabler/icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import * as XLSX from "xlsx";
import { EmployeeService } from "../../../users/interactor/EmployeeInteractor";
import { FormStatisticDto } from "../../data/dto/FormStatisticDto";
import { AnswerReportFormExcelResponse } from "../../data/response/AnswerReportFormExcelResponse";
import { toast } from "react-toastify";
import { employeeAuthorityType } from "../AssignSurvey";

const PAGE_SIZE = 10;

const AnswerReport: React.FC = () => {
  const dispatch = useAppDispatch();

  const [fetching, setFetching] = useState(false);

  const formListService = useSelector(
    (state: RootState) => state.FormListState.value
  );

  const formsData = formListService?.data;

  const employeeService = useSelector(
    (state: RootState) => state.EmployeeState.value
  );

  const employeeData = employeeService?.data?.employees || [];

  useEffect(() => {
    if (!formsData) {
      setFetching(true);
      dispatch(FormListService());
    }
    dispatch(EmployeeService());
  }, []);

  useEffect(() => {
    if (formsData?.forms) {
      setFetching(false);
    }
  }, [formsData]);

  const [form, setForm] = useState<any>(null);
  const [userId, setUserId] = useState<any>(null);
  const [pageData, setPageData] = useState<any[]>([]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "date",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [sortedData, setSortedData] = useState<any[]>([]);

  const answerReportFormService = useSelector(
    (state: RootState) => state.AnswerReportFormState.value
  );

  const answerReportFormData = answerReportFormService?.data;

  useEffect(() => {
    if (form && userId) {
      dispatch(AnswerReportFormService({ formId: form, userId: userId }));
    }
  }, [form, userId]);

  useEffect(() => {
    if (answerReportFormData?.formStatisticDtos) {
      let questions = answerReportFormData.formStatisticDtos
        .map((item: FormStatisticDto) => {
          return {
            seo: item.seo,
            question: item.question,
            answer: item.answer,
          };
        })
        .sort((a, b) => a.seo - b.seo);

      setSortedData(questions);
      setPageData(questions.slice(from, to));
      setInitialRecords(questions);
    }
  }, [answerReportFormData]);

  const handleExportToExcel = () => {
    var headers = ["UserId"];

    dispatch(AnswerReportFormExcelService({ formId: form })).then(
      (response) => {
        if (response !== undefined) {
          let responsePayload = response.payload as
            | AnswerReportFormExcelResponse
            | undefined;
          if (responsePayload?.success) {
            setFetching(false);

            const questions = [
              ...responsePayload.data!.answerReportFormExcelDto.questions,
            ];
            questions
              .sort((a, b) => {
                return b.seo - a.seo;
              })
              .forEach((item) => {
                if (headers.indexOf(item.label) === -1) {
                  headers.push(item.label);
                }
              });
            const answers = [
              ...responsePayload.data!.answerReportFormExcelDto.answers,
            ];
            const data: any = answers.map((item) => {
              const answerField = [...item.answerField];
              const values = answerField
                .sort((a, b) => b.seo - a.seo)
                .map((field) => field.value);
              return [item.userId, ...values];
            });

            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

            for (let R = 1; R <= data.length; ++R) {
              for (let C = 10; C <= 11; ++C) {
                const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
                if (worksheet[cellAddress]) {
                  worksheet[cellAddress].z = "@"; // Apply text format
                }
              }
            }

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Tarlalarım");
            XLSX.writeFile(workbook, "Cevap_Raporu.xlsx");
          } else {
            toast.error(responsePayload?.userMessage);
            setFetching(false);
          }
        }
      }
    );
  };

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  const [questionQuery, setQuestionQuery] = useState("");
  const [debouncedQuestionQuery] = useDebouncedValue(questionQuery, 200);

  const [answerQuery, setAnswerQuery] = useState("");
  const [debouncedAnswerQuery] = useDebouncedValue(answerQuery, 200);

  const [seoQuery, setSeoQuery] = useState("");
  const [debouncedSeoQuery] = useDebouncedValue(seoQuery, 200);

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = initialRecords.filter(
        ({ question, answer, seo }) => {
          if (
            debouncedQuestionQuery !== "" &&
            questionQuery &&
            !`${question}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedQuestionQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedAnswerQuery !== "" &&
            answerQuery &&
            !`${answer}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedAnswerQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedSeoQuery !== "" &&
            seoQuery &&
            !`${seo}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedSeoQuery.trim().toLowerCase())
          ) {
            return false;
          }

          return true;
        }
      );
      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "seo") {
        sortedData.sort((a, b) => {
          const valueA = a.seo ? a.seo : -1;
          const valueB = b.seo ? b.seo : -1;
          return sortStatus.direction === "asc" ? a.seo - b.seo : b.seo - a.seo;
        });
      } else if (sortStatus.columnAccessor === "question") {
        sortedData.sort((a, b) => {
          const valueA = a.question ? a.question.toLowerCase() : "";
          const valueB = b.question ? b.question.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "answer") {
        sortedData.sort((a, b) => {
          const valueA = (a.answer ?? "").toLowerCase();
          const valueB = (b.answer ?? "").toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else {
        return true;
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus]);

  const columns = [
    {
      accessor: "seo",
      title: "Sıra",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Sıra numarası"
          description="Belirtilen soru numarasına ait soruları ara."
          placeholder="Soru numarası ile ara..."
          icon={<IconSearch size={16} />}
          value={seoQuery}
          onChange={(e) => setSeoQuery(e.currentTarget.value)}
        />
      ),
      filtering: seoQuery !== "",
    },
    {
      accessor: "question",
      title: "Soru",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Soru"
          description="Belirtilen soruları ara."
          placeholder="Soru ile ara..."
          icon={<IconSearch size={16} />}
          value={questionQuery}
          onChange={(e) => setQuestionQuery(e.currentTarget.value)}
        />
      ),
      filtering: questionQuery !== "",
    },
    {
      accessor: "answer",
      title: "Cevap",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen açıklamayı içeren tarlaları ara."
          placeholder="Tarla adı ara..."
          icon={<IconSearch size={16} />}
          value={answerQuery}
          onChange={(e) => setAnswerQuery(e.currentTarget.value)}
        />
      ),
      filtering: answerQuery !== "",
    },
  ];

  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Formlar", href: "/survey/list" },
        { title: "Cevap Raporu ", href: "#" },
      ]}
      title="Cevap Raporu"
    >
      <Paper
        shadow="md"
        p={20}
        className="widgets"
        bg="white"
        style={{
          borderTopLeftRadius: "23px",
          borderTopRightRadius: "23px",
        }}
      >
        <LoadingOverlay
          visible={fetching || false}
          overlayBlur={2}
          loaderProps={{
            size: "md",
            color: "#0097c4",
            variant: "oval",
          }}
        />
        <Tabs color="#eef0f8" variant="pills" defaultValue="first">
          <Tabs.List
            style={{
              backgroundColor: "#eef0f8",
              paddingTop: "10px",
              paddingBottom: "10px",
              height: "60px",
              display: "flex",
              borderRadius: "24px",
              justifyContent: "space-around",
            }}
          >
            <Tabs.Tab
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                paddingLeft: "101px",
                paddingRight: "101px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
                color: "#000000",
                // Seçili tabın arka plan rengi
              }}
              value="first"
            >
              Cevap Raporu
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="first">
            <Grid>
              <Grid.Col sm={12} md={12}>
                <Grid>
                  <Grid.Col sm={12} md={5}>
                    <Col span={12}>
                      <Text
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins-Medium",
                          color: "#136583",
                        }}
                      >
                        {" "}
                        Form:
                      </Text>
                      {formsData && (
                        <Select
                          placeholder="--- Seçiniz ---"
                          data={
                            formsData.forms.map((form) => ({
                              value: form.id!,
                              label: form.name,
                            })) || []
                          }
                          onChange={(value) => {
                            setForm(value);
                          }}
                          value={form}
                          clearable
                        />
                      )}
                    </Col>
                  </Grid.Col>
                  <Grid.Col sm={12} md={5}>
                    <Col span={12}>
                      <Text
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins-Medium",
                          color: "#136583",
                        }}
                      >
                        {" "}
                        Kullanıcı:
                      </Text>
                      {employeeData && (
                        <Select
                          placeholder="--- Seçiniz ---"
                          data={
                            employeeData.map((employee) => ({
                              value: employee.userId!,
                              label: `${employee.firstName} ${
                                employee.lastName
                              } - ${
                                employeeAuthorityType.find(
                                  (authorityType) =>
                                    authorityType.value ===
                                    employee.authorityType
                                )?.text ?? ""
                              }`,
                            })) || []
                          }
                          onChange={(value) => {
                            setUserId(value);
                          }}
                          value={userId}
                          clearable
                        />
                      )}
                    </Col>
                  </Grid.Col>
                  <Grid.Col sm={12} md={2}>
                    <Col span={12}>
                      <Button
                        style={{
                          backgroundColor: "#0097c4",
                          paddingBottom: "11.5px",
                          paddingTop: "11.5px",
                          width: "100%",
                          borderRadius: 32,
                          color: "#FFFFFF",
                          textAlign: "center",
                          fontSize: "12px",
                          alignItems: "right",
                          fontFamily: "Poppins-Medium",
                        }}
                        onClick={() => {
                          handleExportToExcel();
                        }}
                      >
                        Excel'e Aktar
                      </Button>
                    </Col>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col sm={12} md={12} style={{ marginTop: "20px" }}>
                <DataTable
                  withColumnBorders
                  highlightOnHover
                  minHeight={250}
                  noRecordsText="Form Bulunamadı..."
                  className="datatable"
                  records={pageData}
                  columns={columns}
                  totalRecords={sortedData.length}
                  recordsPerPage={PAGE_SIZE}
                  page={page}
                  onPageChange={(p) => setPage(p)}
                  fetching={fetching}
                  loaderColor="#0097c4"
                  sortStatus={sortStatus}
                  onSortStatusChange={handleSortStatusChange}
                  sortIcons={{
                    sorted: <IconSortDescending size={14} />,
                    unsorted: <IconSortDescending size={14} />,
                  }}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </MainWrapper>
  );
};

export default AnswerReport;
