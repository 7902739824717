import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import {
  AddImagesResponse,
  AddSeasonNotesResponse,
  AddSeasonsResponse,
  AgrovisioFieldDatesResponse,
  AgrovisioFieldImageResponse,
  AgrovisioImageTypesResponse,
  AnalysisResponse,
  ChangeFieldAnalysisResponse,
  ChangeFieldInfoResponse,
  ChangeSeasonNotesResponse,
  ChangeSeasonsResponse,
  FieldInfoResponse,
  GetImagesResponse,
  GetNDVIScores,
  GetNotificationResponse,
  GetSeasonsInfoResponse,
  GetSeasonsNotesInfoResponse,
  CorporateSatelliteResponse,
  SeasonNoteTitlesResponse,
  UpdateFieldAnalysisResponse,
} from "../data/response/FieldDetailResponses";
import { ServiceState } from "../../common/data/ServiceState";
import {
  GetFieldIdRequests,
  GetFieldsResponse,
  AddSeasonNotes,
  SeasonStatusChangeRequests,
  SeasonNotesStatusChangeRequests,
  AddSeasonRequests,
  AddNotesRequest,
  AddImageRequests,
  UpdateFieldAnalysis,
  FieldAnalysis,
  DummyRequests,
  AgrovisioFieldDatesRequest,
  AgrovisioFieldImageRequest,
  CorporateSatelliteRequest,
  AddFormFieldVisitorResponse,
  AddFormFieldVisitorRequest,
  FormFieldVisitorsRequest,
  FormFieldVisitorsResponse,
} from "../data/request/FieldDetailRequests";
import { BaseResponse } from "../../common/data/BaseResponse";
import { debug } from "console";

// Async Thunk action creator
export const fetchFieldInfo = createAsyncThunk(
  "field/fetchFieldInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<FieldInfoResponse>(
        `corporate/fields/${request.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const changeFieldInfo = createAsyncThunk(
  "field/changeFieldInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<ChangeFieldInfoResponse>(
          `corporate/fields/${request.id}`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Async Thunk action creator
export const fetchDeleteSeason = createAsyncThunk(
  "field/deleteSeason",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().delete(
        `/seasons/${request.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteSeasonNote = createAsyncThunk(
  "field/deleteSeasonNote",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().delete(
        `notes/season/${request.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteNoteImage = createAsyncThunk(
  "field/deleteNoteImage",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().delete(
        `images/seasonnote/${request.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchChangeSeasonInfo = createAsyncThunk(
  "field/changeSeasonInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<ChangeSeasonsResponse>(
          `/seasons/${request.id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchImages = createAsyncThunk(
  "field/changeSeasonInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<GetImagesResponse>(
        `/images/${request.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchSeasonNoteTitle = createAsyncThunk(
  "field/fetchSeasonNoteTitle",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      let customOption = [{ key: "custom", value: "Kendim Girmek İstiyorum" }];
      const response =
        await AxiosHelper.getInstance().get<SeasonNoteTitlesResponse>(
          `/parameter/parameters?ParameterType=4&id=${request.id}`
        );
      response.data.data.parameters =
        response.data.data.parameters.concat(customOption);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateChangeSeasonInfo = createAsyncThunk(
  "field/changeSeasonInfo",
  async (request: SeasonStatusChangeRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<ChangeSeasonsResponse>(
          `/seasons/${request.id}`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Async Thunk action creator
export const updateSeasonNotesInfo = createAsyncThunk(
  "field/updateSeasonInfo",
  async (request: SeasonNotesStatusChangeRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<ChangeSeasonNotesResponse>(
          `notes/season/${request.id}`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateFieldAnalysisInfo = createAsyncThunk(
  "field/fieldAnalysis",
  async (request: UpdateFieldAnalysis, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<UpdateFieldAnalysisResponse>(
          `seasons/analysis`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchChangeFieldAnalysisInfo = createAsyncThunk(
  "field/changeFieldAnalysisInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<ChangeFieldAnalysisResponse>(
          `/seasons/${request.id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Async Thunk action creator
export const addSeasons = createAsyncThunk(
  "field/addseasons",
  async (request: AddSeasonRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().post<AddSeasonsResponse>(
        "seasons",
        request
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const addImages = createAsyncThunk(
  "field/addImages",
  async (request: AddImageRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().post<
        BaseResponse<AddImagesResponse>
      >("images/SeasonNoteImage", request, {
        headers: { "content-type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const addImagesFormData = createAsyncThunk(
  "field/addImages",
  async (request: FormData, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().post<
        BaseResponse<AddImagesResponse>
      >("images/SeasonNoteImage", request, {
        headers: { "content-type": "multipart/form-data" },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const addSeasonNotes = createAsyncThunk(
  "field/addseasonsnotes",
  async (request: AddNotesRequest, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().post<
        BaseResponse<AddSeasonNotes>
      >("notes/season", request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchNdVIScoreInfo = createAsyncThunk(
  "field/fetchNDVIScoreInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<GetNDVIScores>(
        `/corporate/services/satellites/${request.id}/score`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchNotificationsInfo = createAsyncThunk(
  "field/fetchNotificationsInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<GetNotificationResponse>(
          `/corporate/inbox/count?FieldId=${request.id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchUsersFieldInfo = createAsyncThunk(
  "field/fetcUsersFieldInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<GetFieldsResponse>(
        `corporate/fields/${request.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchSeasonsInfo = createAsyncThunk(
  "field/fetSeasonsInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<GetSeasonsInfoResponse>(
          `seasons?fieldId=${request.id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchSeasonNoteInfo = createAsyncThunk(
  "field/fetchSeasonNoteInfo",
  async (request: GetFieldIdRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<GetSeasonsNotesInfoResponse>(
          `/notes/season/note/${request.id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchFieldAnalysisValues = createAsyncThunk(
  "field/fetchFieldAnalysisValues",
  async (request: FieldAnalysis, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<AnalysisResponse>(
        `/parameter/parameters?ParameterType=7`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const agrovisioFieldImage = createAsyncThunk(
  "services/agrovisio/field/image",
  async (request: AgrovisioFieldImageRequest, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<AgrovisioFieldImageResponse>(
          `/services/agrovisio/field/image?AgrovisioFieldId=${request.agrovisioFieldId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const agrovisioImageTypes = createAsyncThunk(
  "services/agrovisio/imagetypes",
  async (request: DummyRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<AgrovisioImageTypesResponse>(
          `/services/agrovisio/imagetypes`
        );
      return response.data;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const agrovisioFieldDates = createAsyncThunk(
  "services/agrovisio/field/dates",
  async (request: AgrovisioFieldDatesRequest, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<AgrovisioFieldDatesResponse>(
          `/services/agrovisio/field/dates?FieldId=${request.fieldId}&AgrovisioUserId=${request.agrovisioUserId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const corporateSatellite = createAsyncThunk(
  "services/satellites",
  async (request: CorporateSatelliteRequest, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<any>(
        `/corporate/services/satellites/${request.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialStateAddSeason: ServiceState<AddSeasonsResponse> = {};

const initialState: ServiceState<FieldInfoResponse> = {};

const initialNDVIState: ServiceState<GetNDVIScores> = {};

const initialNotificationsState: ServiceState<GetNotificationResponse> = {};

const initialSeasonNotesState: ServiceState<GetSeasonsNotesInfoResponse> = {};

const initialChangeSeasonInfoState: ServiceState<ChangeSeasonsResponse> = {};

const initialAddSeasonNotesState: ServiceState<AddSeasonNotesResponse> = {};

const initialChangeSeasonNotesState: ServiceState<ChangeSeasonNotesResponse> =
  {};

const initialChangeFieldInfoState: ServiceState<ChangeFieldInfoResponse> = {};

const initialChangeSeasonStatusState: ServiceState<ChangeSeasonsResponse> = {};

const initialGetImagesState: ServiceState<GetImagesResponse> = {};

const initialAddImagesState: ServiceState<AddImagesResponse> = {};

const initialUpdateFieldAnalysisInfo: ServiceState<UpdateFieldAnalysisResponse> =
  {};

const initialFetchSeasonNoteTitle: ServiceState<SeasonNoteTitlesResponse> = {};

const initialGetFieldAnalysisValues: ServiceState<AnalysisResponse> = {};

const initialAgrovisioFieldImageState: ServiceState<AgrovisioFieldImageResponse> =
  {};

const initialAgrovisioImageTypesState: ServiceState<AgrovisioImageTypesResponse> =
  {};

const initialCorporateSatellite: ServiceState<CorporateSatelliteResponse> = {};

const agrovisioFieldDatesState: ServiceState<AgrovisioFieldDatesResponse> = {};

export const getSeasonNoteTitleSlice = createSlice({
  name: "field/getSeasonNoteTitleSlice",
  initialState: initialFetchSeasonNoteTitle,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSeasonNoteTitle.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchSeasonNoteTitle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchSeasonNoteTitle.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const updateFieldAnalysisInfoSlice = createSlice({
  name: "corparates/fields/updateFieldAnalysis",
  initialState: initialUpdateFieldAnalysisInfo,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateFieldAnalysisInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updateFieldAnalysisInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(updateFieldAnalysisInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getFieldAnalysisValuesSlice = createSlice({
  name: "corparates/fields/getFieldAnalysis",
  initialState: initialGetFieldAnalysisValues,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFieldAnalysisValues.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchFieldAnalysisValues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchFieldAnalysisValues.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const changeSeasonInfoSlice = createSlice({
  name: "corparates/fields/changeSeasonSlice",
  initialState: initialChangeSeasonInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangeSeasonInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchChangeSeasonInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchChangeSeasonInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const deleteSeasonNoteSlice = createSlice({
  name: "corparates/fields/deleteSeasonNoteSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteSeasonNote.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(deleteSeasonNote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(deleteSeasonNote.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const deleteImageSlice = createSlice({
  name: "corparates/fields/deleteNoteImageSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteNoteImage.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(deleteNoteImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(deleteNoteImage.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const addSeasonNotesSlice = createSlice({
  name: "corparates/fields/addSeasonNotesSlice",
  initialState: initialAddSeasonNotesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addSeasonNotes.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(addSeasonNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(addSeasonNotes.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const addImageSlice = createSlice({
  name: "corparates/fields/in",
  initialState: initialAddImagesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addImagesFormData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(addImagesFormData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload?.data;
      })
      .addCase(addImagesFormData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const changeFieldAnalysisInfoSlice = createSlice({
  name: "corparates/fields/changeFieldAnalysis",
  initialState: initialChangeSeasonInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangeSeasonInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchChangeSeasonInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchChangeSeasonInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const updateSeasonNotesInfoSlice = createSlice({
  name: "corparates/fields/changeSeasonNotes",
  initialState: initialChangeSeasonNotesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateSeasonNotesInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updateSeasonNotesInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(updateSeasonNotesInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const updateSeasonSlice = createSlice({
  name: "corparates/fields/update",
  initialState: initialChangeSeasonStatusState,
  reducers: { resetUpdateSeasonData: () => initialChangeSeasonStatusState },
  extraReducers: (builder) => {
    builder
      .addCase(updateChangeSeasonInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updateChangeSeasonInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(updateChangeSeasonInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const { resetUpdateSeasonData } = updateSeasonSlice.actions;

export const closeSeasonSlice = createSlice({
  name: "corparates/fields/deleteSeasonsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeleteSeason.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchDeleteSeason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload?.data;
      })
      .addCase(fetchDeleteSeason.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getImagesInfoSlice = createSlice({
  name: "field/imagesInfo",
  initialState: initialGetImagesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImages.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchImages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchImages.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const addSeasonsSlice = createSlice({
  name: "corparates/fields/addSeasonsSlice",
  initialState: initialStateAddSeason,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addSeasons.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(addSeasons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(addSeasons.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getFieldInfoSlice = createSlice({
  name: "field/fieldInfo",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFieldInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchFieldInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchFieldInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const changeFieldInfoSlice = createSlice({
  name: "field/changeFieldInfo",
  initialState: initialChangeFieldInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeFieldInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(changeFieldInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(changeFieldInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getNDVIScoreSlice = createSlice({
  name: "field/NDVIScoreInfo",
  initialState: initialNDVIState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNdVIScoreInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchNdVIScoreInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchNdVIScoreInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getNotificationsSlice = createSlice({
  name: "field/NotificationsInfo",
  initialState: initialNotificationsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchNotificationsInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchNotificationsInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getUsersSlice = createSlice({
  name: "field/UsersInfo",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFieldInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchFieldInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchFieldInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getSeasonsInfoSlice = createSlice({
  name: "field/SeasonsInfoSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFieldInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchFieldInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchFieldInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const getSeasonsNotesInfoSlice = createSlice({
  name: "field/getSeasonsNotesInfoSlice",
  initialState: initialSeasonNotesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSeasonNoteInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchSeasonNoteInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchSeasonNoteInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});
export const agrovisioFieldImageSlice = createSlice({
  name: "services/agrovisio/field/image",
  initialState: initialAgrovisioFieldImageState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(agrovisioFieldImage.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(agrovisioFieldImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(agrovisioFieldImage.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});
export const agrovisioImageTypesSlice = createSlice({
  name: "services/agrovisio/imagetypes",
  initialState: initialAgrovisioImageTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(agrovisioImageTypes.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(agrovisioImageTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(agrovisioImageTypes.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});
export const agrovisioFieldDatesSlice = createSlice({
  name: "services/agrovisio/field/dates",
  initialState: agrovisioFieldDatesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(agrovisioFieldDates.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(agrovisioFieldDates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(agrovisioFieldDates.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});
export const corporateSatelliteSlice = createSlice({
  name: "services/satellites",
  initialState: initialCorporateSatellite,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(corporateSatellite.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(corporateSatellite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(corporateSatellite.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAddFormFieldVisitor: ServiceState<AddFormFieldVisitorResponse> =
  {};

export const AddFormFieldVisitorService = createAsyncThunk(
  "/form/fieldvisitor/create",
  async (request: AddFormFieldVisitorRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<AddFormFieldVisitorResponse>(
          `/form/form/fieldvisitor`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AddFormFieldVisitorSlice = createSlice({
  name: "/form/fieldvisitor/create",
  initialState: initialAddFormFieldVisitor,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddFormFieldVisitorService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AddFormFieldVisitorService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AddFormFieldVisitorService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormFieldVisitors: ServiceState<FormFieldVisitorsResponse> = {};

export const FormFieldVisitorsService = createAsyncThunk(
  "/form/fieldvisitor/list",
  async (request: FormFieldVisitorsRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<FormFieldVisitorsResponse>(
          `/form/form/fieldvisitor?FieldId=${request.fieldId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormFieldVisitorsSlice = createSlice({
  name: "/form/fieldvisitor/list",
  initialState: initialFormFieldVisitors,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormFieldVisitorsService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormFieldVisitorsService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormFieldVisitorsService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});
