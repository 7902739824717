import { DateValue } from "@mantine/dates";
import { BaseRequest } from "../../../common/data/BaseRequest";
import { FormFieldVisitorsDto } from "../dto/FormFieldVisitorsDto";
import { BaseResponse } from "../../../common/data/BaseResponse";

export class GetFieldRequests extends BaseRequest {}

export interface AddImageRequests {
  id?: string;
  media?: FormData;
}

export class AddSeasonRequests extends BaseRequest {
  fieldId?: string;
  season?: Season;

  constructor(
    fieldId?: string,
    id?: string,
    seasonName?: string,
    startDate?: string,
    endDate?: string,
    cultivationAreaId?: string,
    cultivationAreaName?: string,
    plantId?: string,
    plantName?: string,
    plantVariantId?: string,
    plantVariantName?: string,
    plantingDate?: string,
    estimatedHarvestDate?: string,
    harvestDate?: string,
    title?: string,
    description?: string,
    noteDate?: string,
    thumbPath?: string,
    imagePath?: string,
    seasonClosed?: boolean,
    seasonNotes?: SeasonNote[],
    fieldAnalysis?: FieldAnalysis[]
  ) {
    super();
    this.fieldId = fieldId;
    this.season = {
      id: id || "",
      seasonName: seasonName || "",
      startDate: startDate || "",
      endDate: endDate || "",
      cultivationAreaId: cultivationAreaId || "",
      cultivationAreaName: cultivationAreaName || "",
      plantId: plantId || "",
      plantName: plantName || "",
      plantVariantId: plantVariantId || "",
      plantVariantName: plantVariantName || "",
      plantingDate: plantingDate || "",
      estimatedHarvestDate: estimatedHarvestDate || "",
      harvestDate: harvestDate || "",
      seasonNotes: seasonNotes || [],
      seasonClosed: seasonClosed || false,
      fieldAnalysis: fieldAnalysis || [],
    };
  }
}

export class AddNotesRequest extends BaseRequest {
  seasonId?: string;
  seasonNote?: SeasonNote;

  constructor(seasonId: string, seasonNote: SeasonNote) {
    super();
    this.seasonId = seasonId;
    this.seasonNote = seasonNote;
  }
}

export class SeasonStatusChangeRequests extends BaseRequest {
  id?: String;
  area?: Number;
  cultivationAreaId?: String;
  soilTypeId?: String;
  irrigationTypeId?: String;
  plantingDate?: String;
  estimatedHarvestDate?: String;
  harvestDate?: String;
  seasonEndDate?: String;
  parcel?: Number;
  block?: Number;
  plantId?: String;
  plantVariantId?: String;
  seasonClosed?: boolean;
  seasonName?: string;

  constructor(
    id?: string,
    area?: Number,
    cultivationAreaId?: String,
    soilTypeId?: String,
    irrigationTypeId?: String,
    plantingDate?: String,
    estimatedHarvestDate?: String,
    harvestDate?: String,
    seasonEndDate?: String,
    parcel?: Number,
    block?: Number,
    plantId?: String,
    plantVariantId?: String,
    seasonClosed?: boolean,
    seasonName?: string
  ) {
    super();
    this.id = id;
    this.area = area;
    this.cultivationAreaId = cultivationAreaId;
    this.soilTypeId = soilTypeId;
    this.irrigationTypeId = irrigationTypeId;
    this.plantingDate = plantingDate;
    this.estimatedHarvestDate = estimatedHarvestDate;
    this.harvestDate = harvestDate;
    this.seasonEndDate = seasonEndDate;
    this.parcel = parcel;
    this.block = block;
    this.plantId = plantId;
    this.plantVariantId = plantVariantId;
    this.seasonClosed = seasonClosed;
    this.seasonName = seasonName;
  }
}

export class SeasonNotesStatusChangeRequests extends BaseRequest {
  id?: String;
  title?: String;
  description?: String;

  constructor(id?: string, title?: String, description?: String) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
  }
}

export class UserServicesAllRequest extends BaseRequest {
  id?: string;

  constructor(id?: string) {
    super();
    this.id = id;
  }
}

export class CorporateSatelliteRequest extends BaseRequest {
  id?: string;

  constructor(id?: string) {
    super();
    this.id = id;
  }
}

export class AgrovisioFieldImageRequest extends BaseRequest {
  agrovisioFieldId?: string;

  constructor(agrovisioFieldId?: string) {
    super();
    this.agrovisioFieldId = agrovisioFieldId;
  }
}
export class DummyRequests extends BaseRequest {
  constructor() {
    super();
  }
}
export class AgrovisioFieldDatesRequest extends BaseRequest {
  fieldId?: string;
  agrovisioUserId?: string;
  constructor(fieldId?: string, agrovisioUserId?: string) {
    super();
    this.fieldId = fieldId;
    this.agrovisioUserId = agrovisioUserId;
  }
}
export interface GetFieldDetailInfoResponse {
  field: GetFieldsResponse;
}

export interface GetFieldsResponse {
  id: string;
  userId: string;
  fieldName: string;
  soilTypeId?: string;
  soilTypeName: string;
  irrigationTypeId?: string;
  irrigationTypeName: string;
  address: Address;
  createDate: string;
  updateDate: string;
  seasons: Season[];
  agriculturalNotificationCount: number;
  diseaseNotificationCount: number;
  satelliteNotificationCount: number;
  managers: Manager[];
  farmerName?: string;
  farmerPhoneNumber?: string;
}

export interface Managers extends BaseRequest {
  employeeId: string;
  employeeName: string;
}

export interface Manager extends BaseRequest {
  employeeId: string;
  userId: string;
  employeeName: string;
}

export class GetFieldIdRequests extends BaseRequest {
  id?: string;

  constructor(id?: string) {
    super();
    this.id = id;
  }
}

export class GetUsersField {
  producer?: Producers;
}

export class GetSeasonsInfo extends BaseRequest {
  seasons?: Seasons[];
}

export interface FarmerPhoneNumber {
  countryCode?: string;
  phoneNumber: string;
}

export interface ChangeFieldInfo {
  farmerPhoneNumber: FarmerPhoneNumber;
  farmerFirstName: string;
  farmerLastName: string;
  farmerUserId: string;
  managers: Managers;
}

export interface ChangeSeasonsInfo {
  id: string;
  area: number;
  cultivationAreaId: string;
  soilTypeId: string;
  irrigationTypeId: string;
  plantingDate: string;
  estimatedHarvestDate: string;
  harvestDate: string;
  seasonEndDate: string;
  parcel: number;
  block: number;
  plantId: string;
  plantVariantId: string;
  seasonClosed: boolean;
  seasonName: string;
}

export interface AddSeasons {
  seasonId: string;
}

export interface Seasons {
  id: string;
  seasonName: string;
  startDate: string;
  endDate: string;
  cultivationAreaId: string;
  cultivationAreaName: string;
  plantId: string;
  plantName: string;
  plantVariantId: string;
  plantVariantName: string;
  plantingDate: string;
  estimatedHarvestDate: string;
  harvestDate: string;
  seasonNotes: SeasonNote[];
  seasonClosed: boolean;
  fieldAnalysis: FieldAnalysis[];
}

export interface GetImages {
  images: Image[];
}

export interface SeasonNotes {
  id: string;
  title: string;
  description: PhoneNumberDto[];
  noteDate: Responsibles[];
  images: Image;
  editable: Boolean;
}

export interface Producers {
  name: string;
  surname: string;
  phoneNumberDto: PhoneNumberDto;
  responsibles: Responsibles[];
}

export interface GetNDVIResponse {
  maxNdvi: number;
  minNdvi: number;
  avgNdvi: number;
  score: number;
}

export interface GetNotificationsResponse {
  agriculturalNotificationCount: number;
  weatherNotificationCount: number;
  precipitationNotificationCount: number;
  diseaseNotificationCount: number;
  satelliteNotificationCount: number;
}

export interface PhoneNumberDto {
  countryCode: string;
  phoneNumber: string;
}

export interface Responsibles {
  name: string;
  surname: string;
  phoneNumberDto: PhoneNumberDto[];
  responsibles: Responsibles[];
}

export interface SeasonNoteTitles {
  data: string;
}

export interface Address {
  id: string;
  city: City;
  district: District;
  neighbourhood: Neighbourhood;
  addressText: string;
  latitude: number;
  longitude: number;
  altitude: number;
  parcel: number;
  block: number;
  area: number;
  coordinates: Coordinate[];
}

export interface City {
  id: string;
  tarlaioId: number;
  name: string;
  firstFrost: string;
  lastFrost: string;
}

export interface District {
  id: string;
  tarlaioId: number;
  name: string;
}

export interface Neighbourhood {
  id: string;
  tarlaioId: number;
  name: string;
}

export interface Coordinate {
  addressId: string;
  latitude: number;
  longitude: number;
  level: number;
}

export interface Season {
  id: string;
  seasonName?: string;
  startDate: string;
  endDate: string;
  cultivationAreaId?: string;
  cultivationAreaName: string;
  plantId?: string;
  plantName: string;
  plantVariantId?: string;
  plantVariantName: string;
  plantingDate: string;
  estimatedHarvestDate: string;
  harvestDate: string;
  seasonNotes: SeasonNote[];
  seasonClosed: boolean;
  fieldAnalysis: FieldAnalysis[];
}

export interface AddSeasonNotes {
  seasonNoteId: string;
}

export interface ChangeSeasonNotes {
  id: string;
  title: string;
  description: string;
  isPrivate: boolean;
}

export interface SeasonNote {
  id: string;
  title: string;
  description: string;
  amount?: number;
  noteDate: string;
  isPrivate: boolean;
  editable: boolean;
  images?: Image[];
  corporateOwner?: CorporateOwner;
}

export interface CorporateOwner {
  employeeName: string;
  phoneNumber: string;
  authorityType: number;
}

export interface Image {
  id: string;
  thumbPath: string;
  imagePath: string;
}

export interface AddImages {
  data?: Images[];
}

export interface Images {
  seasonNoteImageId: string;
  seasonNoteId: string;
  thumbPath: string;
  imagePath: string;
}

export interface UpdateFieldAnalysis {
  fieldAnalysis?: FieldAnalysis;
}

export class FieldAnalysis {
  fieldAnalysisId?: string;
  seasonId?: string;
  analysisDate?: Date;
  pH?: number;
  salt?: number;
  saturation?: number;
  lime?: number;
  nitrogen?: number;
  phosphorus?: number;
  potassium?: number;
}

interface FieldAnalysisParameter {
  key: string;
  value: string; // This is a JSON string that you will parse
}

export interface GetFieldAnalysisResponse {
  parameters: FieldAnalysisParameter[];
}

export interface AgrovisioFieldImageDto {
  accessToken?: string;
  url?: string;
}

export interface AgrovisioImageTypesDto {
  agrovisioImageTypes?: AgrovisioImageTypes[];
}
export interface AgrovisioImageTypes {
  id?: string;
  agrovisioImageTypeId?: string;
  imageTypeName?: string;
  agrovisioImageIndexId?: string;
  name?: string;
  subName?: string;
  textName?: string;
}
export interface CorporateSatelliteDto {
  agrovisioFieldId?: string;
  agrovisioUserId?: string;
}
export interface SatelliteService {
  fieldId: string;
  seasonId: string;
  agrovisioUserId: string;
  agrovisioFieldId: string;
  productId: string;
  expireDate: string;
  serviceStatus: number;
  plantName: string;
  fieldName: string;
  city: string;
  district: string;
  userId: string;
  createdDate: string;
}

export interface FertilizationService {
  fieldId: string;
  seasonId: string;
  fieldName: string;
  plantId: string;
  plantName: string;
  plantVariantId: string;
  plantVariantName: string;
  userId: string;
  fertilizationServiceId: string;
  fertilizationServiceName: string;
  farmerName: string;
  farmerPhoneNumber: string;
  city: string;
  district: string;
  demandDate: string;
  adviceGivenDate: string;
  serviceStatus: number;
  createdDate: string;
}

export interface IrrigationService {
  irrigationServiceId: string;
  fieldId: string;
  seasonId: string;
  fieldName: string;
  plantId: string;
  plantName: string;
  plantVariantId: string;
  plantVariantName: string;
  farmerName: string;
  farmerPhoneNumber: string;
  city: string;
  district: string;
  demandDate: string;
  adviceGivenDate: string;
  serviceStatus: number;
  serviceStartDate: string;
  createdDate: string;
}

export interface AgrovisioDatesDto {
  imageTypes: ImageType[];
}

export interface ImageType {
  typeName: string;
  days: Day[];
}

export interface Day {
  day: string;
  images: Image[];
}

export interface Image {
  id: string;
  date: string;
  analysis: Analysis;
}

export interface Analysis {
  validPixelPercentage: any;
  indexes: any[];
}

export interface FormFieldVisitorsRequest {
  fieldId: string;
}

export interface FormFieldVisitorsResponse
  extends BaseResponse<FormFieldVisitorsData> {}

export interface FormFieldVisitorsData {
  formFieldVisitors: FormFieldVisitorsDto[];
}

export interface AddFormFieldVisitorRequest {
  fieldId: string;
}

export interface AddFormFieldVisitorResponse
  extends BaseResponse<AddFormFieldVisitorData> {}
{
}
export interface AddFormFieldVisitorData {}
export interface DeleteFormFieldVisitorRequest {
  formFieldVisitorId: string;
}

export interface DeleteFormFieldVisitorResponse
  extends BaseResponse<DeleteFormFieldVisitorData> {}
{
}
export interface DeleteFormFieldVisitorData {}
