import { Flex, rem, Divider, Skeleton } from "@mantine/core";
import { useState, useEffect } from "react";
import {
  Notification,
  Buildings,
  GlobeHemisphereEast,
  MapTrifold,
  User,
  Icon,
  PencilCircle,
  FilePlus,
  Files,
  Clock,
  ChartBar,
} from "phosphor-react";
import MenuList from "./MenuList";
import { LazyLoadImage } from "react-lazy-load-image-component";

const left_menu_logo =
  process.env.PUBLIC_URL + "/assets/images/left_menu_logo.png";

export interface MenuItem {
  label: string;
  icon?: Icon;
  route?: string;
  menu?: [MenuItem];
  modal?: MenuModalItem;
  authType: string;
  subType: string;
}

export interface MenuModalItem {
  title: string;
  description: string;
  url: string;
  authType: string;
  subType: string;
}
function LeftMenu({ closeButton }: any) {
  const [opened] = useState(false);
  const [currentItem, setCurrentItem] = useState<MenuItem | null>(null);

  const menuItems = [
    {
      label: "Tarlalarım",
      icon: (
        <MapTrifold
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#50aa4c",
          }}
        />
      ),
      route: "/myFields",
      authType: "1,2",
    },
    {
      label: "Bildirimlerim",
      icon: (
        <Notification
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#7169d2",
          }}
        />
      ),
      route: "/notifications",
      authType: "1,2",
    },
    {
      label: "Firma Bilgileri",
      icon: (
        <Buildings
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#373ea4",
          }}
        />
      ),
      route: "/profile",
      authType: "1",
    },
    {
      label: "Kullanıcılar",
      icon: (
        <User
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#373ea4",
          }}
        />
      ),
      route: "/users",
      authType: "1",
    },
    {
      label: "Uydu Görüntüsü",
      icon: (
        <GlobeHemisphereEast
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#f26885",
          }}
        />
      ),
      route: "/satellite",
      authType: "1,2",
      subType: "1,2",
    },
  ];

  if (process.env.REACT_APP_HAS_SURVEY === "true") {
    menuItems.push({
      label: "Görev Listesi",
      icon: (
        <FilePlus
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#373ea4",
          }}
        />
      ),
      route: "/task/list",
      authType: "1",
    });
    menuItems.push({
      label: "Görevlerim",
      icon: (
        <Clock
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#373ea4",
          }}
        />
      ),
      route: "/mytask/list",
      authType: "2",
    });
    menuItems.push({
      label: "Formlar",
      icon: (
        <Files
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#373ea4",
          }}
        />
      ),
      route: "/survey/list",
      authType: "1",
    },
    {
      label: "Dashboard",
      icon: (
        <ChartBar
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#373ea4",
          }}
        />
      ),
      route: "/corporatedashboard",
      authType: "1,2",
    });
  }
  

  const handleSelectedItem = (item: MenuItem) => {
    setCurrentItem(item);
  };

  return (
    <Flex direction="column">
      <Flex justify={"end"} px={rem(10)}>
        {closeButton}
      </Flex>

      <Flex
        direction={"row"}
        gap={rem(5)}
        p={rem(20)}
        align={"center"}
        justify={"center"}
      >
        <LazyLoadImage
          src="/assets/images/login_logo.png"
          rel="preload"
          width={170}
          height={"auto"}
          alt="Login Icon Alt"
          placeholder={<Skeleton />}
          effect="blur"
          style={{ objectFit: "contain" }}
        />
      </Flex>

      <Divider size={"1"} color="#F0EBEB" />
      <Flex justify={"center"} align={"center"}>
        <MenuList
          isOpen={opened}
          list={menuItems.filter(
            (x) =>
              x.authType
                .split(",")
                .indexOf(localStorage.getItem("authorityType") ?? "") !== -1 &&
              x.subType
                ?.split(",")
                .indexOf(localStorage.getItem("subscriptionType") ?? "") !== -1
          )}
          currentItem={currentItem}
          handleSelectedItem={handleSelectedItem}
        />
      </Flex>
    </Flex>
  );
}

export default LeftMenu;
