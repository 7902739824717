import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import FieldDetailRoutes from "../modules/fielddetail/connector/FieldDetailRoutes";
import LoginRoutes from "../modules/login/connector/ModuleRoutes";
import MyFieldsRoutes from "../modules/myfields/connector/ModuleRoutes";
import NotificationsRoutes from "../modules/notifications/connector/ModuleRoutes";
import ProfileRoutes from "../modules/profile/connector/ModuleRoutes";
import SatelliteRoutes from "../modules/satellite/connector/ModuleRoutes";
import UserProfileRoutes from "../modules/userprofile/connector/ModuleRoutes";
import UserRoutes from "../modules/users/connector/ModuleRoutes";
import WeatherRoutes from "../modules/weather/connector/ModuleRoutes";
import SurveyRoutes from "../modules/survey/connector/ModuleRoutes";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router";
import CorporateDashboardRoutes from "../modules/corporatedashboard/connector/ModuleRoutes";

const allowedPaths = [
  "/login",
  "/",
  "/users",
  "/users/add",
  "/weatherDetail",
  "/myFields/detail",
  "/myFields/mapview",
  "/myFields/navigation",
  "/profile",
  "/profile/changepassword",
  "/notifications",
  "/myFields",
  "/myfields/add",
  "/userprofile",
  "/satellite",
  "/survey/list",
  "/survey/add",
  "/survey/update",
  "/survey/display",
  "/survey/assing",
  "/survey/answer/report",
  "/survey/question/report",
  "/task/add",
  "/task/assign",
  "/mytask/list",
  "/task/list",
  "/task/detail",
  "/mytask/detail",
  "/mytask/detail/list",
  "/corporatedashboard",
];

function LoginCheck(props: any) {
  const [windowLoaded, setWindowLoadded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const expDate = token == null ? null : jwtDecode(token).exp;

    const convertedTimestamp = convertDateToTimestamp(
      new Date().toLocaleTimeString()
    );

    if (
      expDate == null ||
      (convertDateToTimestamp != null &&
        expDate < (convertedTimestamp ?? 0) &&
        window.location.pathname !== "/login")
    ) {
      let weatherFav = localStorage.getItem("weatherFav");
      localStorage.clear();
      if (weatherFav !== null) localStorage.setItem("weatherFav", weatherFav);
      navigate("/login");
    } else if (props.pathName.indexOf(allowedPaths) !== -1) {
      navigate("/myFields");
    }

    setWindowLoadded(true);
  }, []);

  const convertDateToTimestamp = (dateString: string) => {
    const date = moment(dateString, "h:mm:ss A");
    if (date.isValid()) {
      const timestamp = date.unix();
      return timestamp;
    } else {
      console.error("Invalid date string format");
      return undefined;
    }
  };

  return (
    <>
      {windowLoaded && (
        <>
          <LoginRoutes />
          <UserRoutes />
          <WeatherRoutes />
          <FieldDetailRoutes />
          <MyFieldsRoutes />
          <ProfileRoutes />
          <NotificationsRoutes />
          <UserProfileRoutes />
          <SatelliteRoutes />
          <SurveyRoutes />
          <CorporateDashboardRoutes />
        </>
      )}
    </>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={<LoginCheck pathName={window.location.pathname} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
