import React, { useEffect, useState } from "react";
import {
  Tabs,
  Paper,
  rem,
  Stack,
  Grid,
  Col,
  Space,
  LoadingOverlay,
  Table,
  Group,
  Button,
  TextInput,
  Box,
} from "@mantine/core";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import { useNavigate, useParams } from "react-router-dom";
import AddTaskCard from "./AddTaskCard";
import {
  AnswerReportFormExcelService,
  AssignFormListService,
  DetailFormTaskService,
} from "../../interractor/SurveyInterractor";
import { useAppDispatch } from "../../connector/ModuleHook";
import { useSelector } from "react-redux";
import { RootState } from "../../connector/ModuleStore";
import moment from "moment";
import {
  IconSearch,
  IconSortDescending,
  IconFileDownload,
  IconArrowLeft,
} from "@tabler/icons-react";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";
import { EmployeeService } from "../../../users/interactor/EmployeeInteractor";
import { DatePicker, DatesProvider } from "@mantine/dates";
import { FormAssingDto } from "../../data/dto/FormAssingDto";
import { formFieldEmployeeState } from "../../data/dto/FormFieldEmployeeState";
import { employeeAuthorityType } from "../../data/dto/AuthorityType";
import { useDebouncedValue } from "@mantine/hooks";
import * as XLSX from "xlsx";
import { AnswerReportFormExcelResponse } from "../../data/response/AnswerReportFormExcelResponse";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const PAGE_SIZE = 10;

const DetailTaskForm: React.FC = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setFetching(true);
    if (id) {
      dispatch(DetailFormTaskService({ formTaskId: id }));
    }
  }, [id]);

  const detailFormTaskService = useSelector(
    (state: RootState) => state.DetailFormTaskState.value
  );

  const detailFormTaskData = detailFormTaskService?.data?.formTaskDto;

  useEffect(() => {
    if (detailFormTaskData) {
      setFetchingMain(false);
    }
  }, [detailFormTaskData]);

  //---- mytask field detail

  const [pageData, setPageData] = useState<any[]>([]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "createdDate",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [fetchingMain, setFetchingMain] = useState(true);
  const [fetching, setFetching] = useState(true);

  const [nameQuery, setNameQuery] = useState("");
  const [debouncedNameQuery] = useDebouncedValue(nameQuery, 200);
  const [fieldNameQuery, setFieldNameQuery] = useState("");
  const [debouncedFieldNameQuery] = useDebouncedValue(fieldNameQuery, 200);
  const [employeeNameQuery, setEmployeeQuery] = useState("");
  const [debouncedEmployeeQuery] = useDebouncedValue(employeeNameQuery, 200);
  const [employeeAuthorityTypeQuery, setEmployeeAuthorityTypeNameQuery] =
    useState("");
  const [debouncedEmployeeAuthorityType] = useDebouncedValue(
    employeeAuthorityTypeQuery,
    200
  );
  const [formFieldEmployeeStateQuery, setFormFieldEmployeeStateQuery] =
    useState("");
  const [debouncedEFormFieldEmployeeStateQuery] = useDebouncedValue(
    formFieldEmployeeStateQuery,
    200
  );

  const [createdDateQuery, setCreatedDateQuery] = useState("");
  const [debouncedCreatedDateQuery] = useDebouncedValue(createdDateQuery, 200);

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  useEffect(() => {
    setFetching(true);
    if (id) {
      dispatch(MyFieldService());
      dispatch(EmployeeService());
      dispatch(
        AssignFormListService({
          formTaskId: id,
        })
      );
    }
  }, [id]);

  const employeeService = useSelector(
    (state: RootState) => state.EmployeeState.value
  );

  const employeeData = employeeService?.data?.employees || [];

  const myFieldService = useSelector(
    (state: RootState) => state.MyFieldState.value
  );
  const myFieldData = myFieldService?.data?.fields;

  const assignFormListService = useSelector(
    (state: RootState) => state.AssignFormListState.value
  );
  const assignFormListData = assignFormListService?.data?.assingDtos;

  useEffect(() => {
    if (assignFormListData && employeeData && myFieldData) {
      let assignList = assignFormListData.map((item: FormAssingDto) => {
        const employeeFullNames = item.employeeIds
          ? item.employeeIds
              .map((employeeId: string) => {
                const employee = employeeData.find(
                  (emp) => emp.id === employeeId
                );
                return employee
                  ? `${employee.firstName} ${employee.lastName}`
                  : null;
              })
              .filter(Boolean)
          : [];

        return {
          id: item.id,
          name: item.formName,
          fieldName: item.fieldId
            ? myFieldData.find((field) => field.id === item.fieldId)?.fieldName
            : "-",
          employee: employeeFullNames ? employeeFullNames.join(", ") : "-",
          employeeAuthorityType: employeeAuthorityType.find(
            (authorityType) =>
              authorityType.value === item.employeeAuthorityType
          )?.text,
          taskFieldEmployeeState: formFieldEmployeeState.find(
            (employeeState) =>
              employeeState.value === item.taskFieldEmployeeState
          )?.text,
          createdDate: moment(item.createdDate)
            .add(3, "hour")
            .format("DD.MM.YYYY HH:mm"),
        };
      });
      setPageData(assignList);
      setInitialRecords(assignList);
      setFetching(false);
    }
  }, [assignFormListData, employeeData, myFieldData]);

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = initialRecords.filter(
        ({
          name,
          fieldName,
          employee,
          employeeAuthorityType,
          taskFieldEmployeeState,
          createdDate,
        }) => {
          if (
            debouncedNameQuery !== "" &&
            nameQuery &&
            !`${name}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedFieldNameQuery !== "" &&
            fieldNameQuery &&
            !`${fieldName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFieldNameQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedEmployeeQuery !== "" &&
            employeeNameQuery &&
            !`${employee}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedEmployeeQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedEmployeeAuthorityType !== "" &&
            employeeAuthorityTypeQuery &&
            !`${employeeAuthorityType}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedEmployeeAuthorityType.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedEFormFieldEmployeeStateQuery !== "" &&
            formFieldEmployeeStateQuery &&
            !`${taskFieldEmployeeState}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(
                debouncedEFormFieldEmployeeStateQuery.trim().toLowerCase()
              )
          ) {
            return false;
          }
          if (
            debouncedCreatedDateQuery !== "" &&
            createdDateQuery &&
            moment(createdDate, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY") !==
              debouncedCreatedDateQuery
          ) {
            return false;
          }

          return true;
        }
      );
      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "name") {
        sortedData.sort((a, b) => {
          const valueA = a.name ? a.name : "";
          const valueB = b.name ? b.name : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "fieldName") {
        sortedData.sort((a, b) => {
          const valueA = a.fieldName ? a.fieldName.toLowerCase() : "";
          const valueB = b.fieldName ? b.fieldName.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "employee") {
        sortedData.sort((a, b) => {
          const valueA = a.employee ? a.employee.toLowerCase() : "";
          const valueB = b.employee ? b.employee.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "employeeAuthorityType") {
        sortedData.sort((a, b) => {
          const valueA = a.employeeAuthorityType
            ? a.employeeAuthorityType.toLowerCase()
            : "";
          const valueB = b.employeeAuthorityType
            ? b.employeeAuthorityType.toLowerCase()
            : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "formFieldEmployeeState") {
        sortedData.sort((a, b) => {
          const valueA = a.taskFieldEmployeeState
            ? a.taskFieldEmployeeState.toLowerCase()
            : "";
          const valueB = b.taskFieldEmployeeState
            ? b.taskFieldEmployeeState.toLowerCase()
            : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "createdDate") {
        sortedData.sort((a, b) => {
          const dateA = a.createdDate
            ? moment(a.createdDate, "DD.MM.YYYY HH:mm")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan
          const dateB = b.createdDate
            ? moment(b.createdDate, "DD.MM.YYYY HH:mm")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan

          return sortStatus.direction === "asc"
            ? dateA.diff(dateB)
            : dateB.diff(dateA);
        });
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus]);

  const columns = [
    {
      accessor: "name",
      title: "Form Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen isimdeki formları ara."
          placeholder="Form adı ara..."
          icon={<IconSearch size={16} />}
          value={nameQuery}
          onChange={(e) => setNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: nameQuery !== "",
    },
    {
      accessor: "fieldName",
      title: "Tarla Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen açıklamayı içeren tarlaları ara."
          placeholder="Tarla adı ara..."
          icon={<IconSearch size={16} />}
          value={fieldNameQuery}
          onChange={(e) => setFieldNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: fieldNameQuery !== "",
    },
    {
      accessor: "employee",
      title: "Çalışan",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Çalışan içeren formları ara."
          placeholder="Çalışan adı ara..."
          icon={<IconSearch size={16} />}
          value={employeeNameQuery}
          onChange={(e) => setEmployeeQuery(e.currentTarget.value)}
        />
      ),
      filtering: employeeNameQuery !== "",
    },

    {
      accessor: "employeeAuthorityType",
      title: "Yetki Tipi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Yetki Tipi"
          description="Yetki tipi içeren formları ara."
          placeholder="Yetki tipi ara..."
          icon={<IconSearch size={16} />}
          value={employeeAuthorityTypeQuery}
          onChange={(e) =>
            setEmployeeAuthorityTypeNameQuery(e.currentTarget.value)
          }
        />
      ),
      filtering: employeeAuthorityTypeQuery !== "",
    },
    {
      accessor: "taskFieldEmployeeState",
      title: "Durumu",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Görev durumu"
          description="Görev durumu içeren formları ara."
          placeholder="Görev durumu ile ara..."
          icon={<IconSearch size={16} />}
          value={formFieldEmployeeStateQuery}
          onChange={(e) =>
            setFormFieldEmployeeStateQuery(e.currentTarget.value)
          }
        />
      ),
      filtering: formFieldEmployeeStateQuery !== "",
    },
    {
      accessor: "createdDate",
      title: "Atama Tarihi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: ({ close }: { close: any }) => (
        <DatesProvider
          settings={{
            locale: dayjs.locale("tr"),
            firstDayOfWeek: 1,
            weekendDays: [0, 6],
          }}
        >
          <DatePicker
            maxDate={new Date()}
            value={
              createdDateQuery == ""
                ? null
                : moment(createdDateQuery, "DD.MM.YYYY").toDate()
            }
            onChange={(date) =>
              setCreatedDateQuery(moment(date).format("DD.MM.YYYY"))
            }
          />
          <Button
            disabled={createdDateQuery == ""}
            color="red"
            onClick={() => {
              setCreatedDateQuery("");
              close();
            }}
          >
            Reset
          </Button>
        </DatesProvider>
      ),
      filtering: createdDateQuery !== "",
    },
  ];

  //end mytask field detail
  const handleExportToExcel = () => {
    var headers = ["UserId"];

    dispatch(
      AnswerReportFormExcelService({ formId: detailFormTaskData?.formId ?? "" })
    ).then((response) => {
      if (response !== undefined) {
        let responsePayload = response.payload as
          | AnswerReportFormExcelResponse
          | undefined;
        if (responsePayload?.success) {
          setFetching(false);

          const questions = [
            ...responsePayload.data!.answerReportFormExcelDto.questions,
          ];
          questions
            .sort((a, b) => {
              return b.seo - a.seo;
            })
            .forEach((item) => {
              if (headers.indexOf(item.label) === -1) {
                headers.push(stripHtml(item.label));
              }
            });
          const answers = [
            ...responsePayload.data!.answerReportFormExcelDto.answers,
          ];
          const data: any = answers.map((item) => {
            const answerField = [...item.answerField];
            const values = answerField
              .sort((a, b) => b.seo - a.seo)
              .map((field) => field.value);
            return [item.userId, ...values];
          });

          const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

          for (let R = 1; R <= data.length; ++R) {
            for (let C = 10; C <= 11; ++C) {
              const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
              if (worksheet[cellAddress]) {
                worksheet[cellAddress].z = "@"; // Apply text format
              }
            }
          }

          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Tarlalarım");
          XLSX.writeFile(workbook, "Cevap_Raporu.xlsx");
        } else {
          toast.error(responsePayload?.userMessage);
          setFetching(false);
        }
      }
    });
  };

  const stripHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Görev Listesi", href: "/task/list" },
        { title: "Görev Detay", href: "#" },
      ]}
      title={"Görev Detay"}
    >
      <>
        <Box
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="outline"
            color="#000000"
            fullWidth
            style={{
              color: "#FFF",
              borderColor: "#000000",
              borderRadius: "2rem",
              fontWeight: 300,
              width: "100px",
              backgroundColor: "red",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconArrowLeft /> Geri
          </Button>
        </Box>
        <Paper
          shadow="md"
          p={20}
          className="widgets"
          bg="white"
          style={{
            borderTopLeftRadius: "23px",
            borderTopRightRadius: "23px",
          }}
        >
          <Tabs color="#eef0f8" variant="pills" defaultValue="first">
            <Tabs.List
              style={{
                backgroundColor: "#eef0f8",
                paddingTop: "10px",
                paddingBottom: "10px",
                display: "flex",
                borderRadius: "24px",
                justifyContent: "space-around",
              }}
            >
              <Tabs.Tab
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  paddingLeft: "101px",
                  paddingRight: "101px",
                  borderRadius: "24px",
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  // Seçili tabın arka plan rengi
                }}
                value="first"
              >
                Görev Detay
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  paddingLeft: "101px",
                  paddingRight: "101px",
                  borderRadius: "24px",
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  // Seçili tabın arka plan rengi
                }}
                value="second"
              >
                Atanan Kişi/Tarla Listesi
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  paddingLeft: "101px",
                  paddingRight: "101px",
                  borderRadius: "24px",
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  // Seçili tabın arka plan rengi
                }}
                value="third"
              >
                Cevap Raporu
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="first">
              <Paper p={0} className="widgets" radius={rem(12)} bg="white">
                <Space h={"30px"} />

                <Stack
                  dir="column"
                  p={20}
                  justify={"flex-start"}
                  align={"flex-start"}
                  pos={"relative"}
                >
                  <LoadingOverlay
                    visible={fetchingMain}
                    overlayBlur={2}
                    loaderProps={{
                      size: "md",
                      color: "#0097c4",
                      variant: "oval",
                    }}
                    pos={"absolute"}
                  />
                  <Grid>
                    <Grid.Col sm={12} md={12}>
                      <Col span={12}>
                        {detailFormTaskData && (
                          <Table>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Görev Adı:</b>
                              </td>
                              <td>{detailFormTaskData.name}</td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Açıklama:</b>
                              </td>
                              <td>
                                {" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: detailFormTaskData.description,
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Başlangıç Tarihi:</b>
                              </td>
                              <td>
                                {moment(detailFormTaskData.startDate).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Bitiş Tarihi:</b>
                              </td>
                              <td>
                                {moment(detailFormTaskData.endDate).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Form Adı:</b>
                              </td>
                              <td>{detailFormTaskData.formName}</td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Konum Kontrolü Var Mı?:</b>
                              </td>
                              <td>{`${
                                detailFormTaskData.locationChecking
                                  ? "Var"
                                  : "Yok"
                              }`}</td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Fotoraf Ekleme Zorunlu Mu?:</b>
                              </td>
                              <td>{`${
                                detailFormTaskData.additionalPhoto
                                  ? "Var"
                                  : "Yok"
                              }`}</td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Atanmış Kişi Sayısı:</b>
                              </td>
                              <td>{detailFormTaskData.assignedCount ?? 0}</td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Tamamlanmış Kişi Sayısı:</b>
                              </td>
                              <td>{detailFormTaskData.completedCount ?? 0}</td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "200px", verticalAlign: "top" }}
                              >
                                <b>Devam Eden Kişi Sayısı:</b>
                              </td>
                              <td>
                                {(detailFormTaskData.assignedCount ?? 0) -
                                  (detailFormTaskData.completedCount ?? 0)}
                              </td>
                            </tr>
                          </Table>
                        )}
                      </Col>
                    </Grid.Col>

                    <Grid.Col sm={12} md={6}>
                      <Col span={12}></Col>
                    </Grid.Col>
                  </Grid>
                  <Space h={"20px"} />
                </Stack>
              </Paper>
            </Tabs.Panel>
            <Tabs.Panel value="second">
              <Paper p={0} className="widgets" radius={rem(12)} bg="white">
                <Space h={"30px"} />
                <Grid>
                  <Grid.Col sm={12} md={12} style={{ marginTop: "20px" }}>
                    <DataTable
                      withColumnBorders
                      highlightOnHover
                      minHeight={250}
                      noRecordsText="Form Bulunamadı..."
                      className="datatable"
                      records={pageData}
                      columns={columns}
                      totalRecords={sortedData.length}
                      recordsPerPage={PAGE_SIZE}
                      page={page}
                      onPageChange={(p) => setPage(p)}
                      fetching={fetching}
                      loaderColor="#0097c4"
                      sortStatus={sortStatus}
                      onSortStatusChange={handleSortStatusChange}
                      sortIcons={{
                        sorted: <IconSortDescending size={14} />,
                        unsorted: <IconSortDescending size={14} />,
                      }}
                    />
                  </Grid.Col>
                </Grid>
                <Space h={"20px"} />
              </Paper>
            </Tabs.Panel>
            <Tabs.Panel value="third">
              <Paper p={0} className="widgets" radius={rem(12)} bg="white">
                <Space h={"30px"} />
                <Grid>
                  <Grid.Col sm={12} md={12} style={{ marginTop: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "300px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                      onClick={() => {
                        handleExportToExcel();
                      }}
                    >
                      <IconFileDownload /> Excel'e Aktar
                    </Button>
                  </Grid.Col>
                </Grid>
                <Space h={"20px"} />
              </Paper>
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </>
    </MainWrapper>
  );
};

export default DetailTaskForm;
